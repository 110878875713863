import React, { useState, useEffect, useRef } from "react";
import {
    Flex,
    useColorModeValue,
    Text,
    Box,
    Button,
    Wrap,
    RadioGroup,
    Radio,
    Stack,
    WrapItem,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    TableContainer,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import PdfIcon from 'assets/img/layout/pdf_icon_download.png';
import LoaderBlack from 'components/loading/LoaderBlack';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';

export default function GerarPdfLancarProducao() {

    const pdfRef = useRef();
    const history = useHistory();
    const [arrayAlternacia, setArrayAlternacia] = useState([]);
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [arrayValorTarefa1, setArrayValorTarefa1] = useState([]);
    const [arrayValorTarefa2, setArrayValorTarefa2] = useState([]);
    const [arrayValorTarefa3, setArrayValorTarefa3] = useState([]);
    const [arrayValorTarefa4, setArrayValorTarefa4] = useState([]);
    const [arrayValorTarefaTotal, setArrayValorTarefaTotal] = useState([]);
    const [totalValorTarefa1, setTotalValorTarefa1] = useState('0');
    const [totalValorTarefa2, setTotalValorTarefa2] = useState('0');
    const [totalValorTarefa3, setTotalValorTarefa3] = useState('0');
    const [totalValorTarefa4, setTotalValorTarefa4] = useState('0');
    const [totalValorGeral, setTotalValorGeral] = useState('0');
    const [dtValorTarefa, setDtValorTarefa] = useState('');
    const [dtValorTarefa2, setDtValorTarefa2] = useState('');
    const [dtValorTarefa3, setDtValorTarefa3] = useState('');
    const [dtValorTarefa4, setDtValorTarefa4] = useState('');

    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [totalPrevMes, setTotalPrevMes] = useState('');
    const [descMesAno, setDescMesAno] = useState('');
    const [arrayDadosGeralCompleto, setArrayDadosGeralCompleto] = useState([]);
    const [idTipoLancamento, setIdTipoLancamento] = useState('2');

    const [nomeUa, setNomeUa] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [idUf, setIdUf] = useState('');

    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [arrayComTodasAsLetras, setArrayComTodasAsLetras] = useState([]);
    const [nomeRelatorio, setNomeRelatorio] = useState('Lancar-producao');
    const [loading, setLoading] = useState(false);
    const [marginText, setMarginText] = useState('0px');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGray = 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1))';
    const GRADIENTE = 'linear-gradient(to bottom, #e6e6e6, #ffffff, #e6e6e6)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nomeMunicipioP = localStorage.getItem('@HE-nomeMunicipioCadastradadoNaUa');
                const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
                const nmUa = localStorage.getItem('@HE-nomeUa');
                const nmRelatorio = `Acompanhamento-producao-${dia}-${nmMes}-${ano}`;

                setNomeRelatorio(nmRelatorio);
                setNomeRelatorio(nmRelatorio);
                setNomeMunicipio(nomeMunicipioP);
                setIdUf(idUfP);
                setNomeUa(nmUa);

                const tePr = localStorage.getItem('@HE-dadosTelaGerarPdfLancarProducao');
                const obj = JSON.parse(tePr);
                const idUaX = obj.idUa;
                const idSafraX = obj.idSafra;
                const idSafraPessoaX = obj.idSafraPessoa;
                const idPessoaX = obj.idPessoa;
                const nomeX = obj.nomeSeringueiro;
                const numMesSelecionadoP = obj.numMes;
                const numAnoSelecionadoP = obj.numAno;

                setNomeSeringueiro(nomeX);
                setDescMesAno(obj.descMesAno);
                setTotalPrevMes(obj.totalPrevMes);

                const url3 = `producao/listaDadosLancarProducao?idUa=${idUaX}&idSafra=${idSafraX}&idSafraPessoa=${idSafraPessoaX}&idPessoa=${idPessoaX}&mes=${numMesSelecionadoP}&ano=${numAnoSelecionadoP}`;
                const response = await Api.getRequest(url3);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    const mesesLancarProducao = res.listMesesLancarProducao.filter(x => x.numMes == numMesSelecionadoP);
                    if (mesesLancarProducao.length > 0) {
                        const mesSelecionado = mesesLancarProducao[0];

                        const totalValorMesP = mesSelecionado.totalValorMes;
                        const totalPrevMesP = mesSelecionado.totalPrevMes;
                        const idTipoLancamentoP = mesSelecionado.idTipoLancamento;
                        const qtdTarefas2 = mesSelecionado.idQuantidadeTarefas != null && mesSelecionado.idQuantidadeTarefas != '' ? mesSelecionado.idQuantidadeTarefas : '3';

                        setTotalPrevMes(`${totalPrevMesP}`)
                        setIdTipoLancamento(`${idTipoLancamentoP}`);
                        setTotalValorGeral(`${totalValorMesP}`);
                        setAlternanciaSelecionado(qtdTarefas2);

                        const arrayTarefaSafra = await criarArrayComTodasTarefas(res.listTarefaSafraItem);
                        setArrayComTodasAsLetras(arrayTarefaSafra);
                        atualizaDadosGeralNaTela(qtdTarefas2, mesSelecionado.listProducaoPessoaAlternanciaItem, arrayTarefaSafra);
                    }
                    setArrayDadosGeralCompleto(mesesLancarProducao[0]);

                    const prodP = res.producaoValorMesDto;
                    if (prodP != null) {

                        const totalValorTarefa1P = prodP.totalValorTarefa1 != null ? prodP.totalValorTarefa1 : 0;
                        const totalValorTarefa2P = prodP.totalValorTarefa2 != null ? prodP.totalValorTarefa2 : 0;
                        const totalValorTarefa3P = prodP.totalValorTarefa3 != null ? prodP.totalValorTarefa3 : 0;
                        const totalValorTarefa4P = prodP.totalValorTarefa4 != null ? prodP.totalValorTarefa4 : 0;
                        const dtValorTarefa1P = prodP.dtValorTarefa1 != null ? prodP.dtValorTarefa1 : '__/__/__';
                        const dtValorTarefa2P = prodP.dtValorTarefa2 != null ? prodP.dtValorTarefa2 : '__/__/__';
                        const dtValorTarefa3P = prodP.dtValorTarefa3 != null ? prodP.dtValorTarefa3 : '__/__/__';
                        const dtValorTarefa4P = prodP.dtValorTarefa4 != null ? prodP.dtValorTarefa4 : '__/__/__';
                        const numMesP = prodP.numMes != null ? Number(prodP.numMes) : 0;
                        const numAnoP = prodP.numAno != null && prodP.numAno != 0 ? `${prodP.numAno}` : '';

                        setTotalValorTarefa1(`${totalValorTarefa1P}`);
                        setTotalValorTarefa2(`${totalValorTarefa2P}`);
                        setTotalValorTarefa3(`${totalValorTarefa3P}`);
                        setTotalValorTarefa4(`${totalValorTarefa4P}`);
                        setDtValorTarefa(dtValorTarefa1P);
                        setDtValorTarefa2(dtValorTarefa2P);
                        setDtValorTarefa3(dtValorTarefa3P);
                        setDtValorTarefa4(dtValorTarefa4P);

                        if (numMesP > 0 && numAnoP.length > 3) {
                            const mes22 = numMesP < 10 && numMesP > 0 ? `0${numMesP}` : `${numMesP}`;
                            const primeiroDiaMes = `${numAnoP}-${mes22}-01`;
                            const ultimoDiaMes = moment(primeiroDiaMes).endOf('month').format('YYYY-MM-DD');
                        }
                    }

                    const arrayTarefaSafra = await criarArrayComTodasTarefas(res.listTarefaSafraItem);
                    setArrayComTodasAsLetras(arrayTarefaSafra);
                    setTelaCarregada(true);
                } else {
                    setTelaCarregada(true);
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } catch (error) {
                setTelaCarregada(true);
                console.log('Erro na classe GerarPdfLancarProducao metodo useEffect', error);
            }
        }
        loaderScreen();
    }, []);

    const criarArrayComTodasTarefas = async (arrayTarefas) => {
        let arrayPess = [];
        if (arrayTarefas.length > 0) {
            await new Promise(resolve => {
                resolve(
                    arrayTarefas.map(function (item) {
                        const obj = {
                            label: item.letraCustomizada,
                            value: `${item.idTarefa}`
                        }
                        arrayPess.push(obj);
                    })
                )
            });
        }
        return arrayPess;
    }

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const atualizaDadosGeralNaTela = async (val = '3', arr = [], arrayComTodasAsLetrasP) => {
        try {
            const num = Number(val);
            const array = arr;
            let arrTarefa = [];
            let arrTamanhoLote = [];
            let arrValorTarefa1 = [];
            let arrValorTarefa2 = [];
            let arrValorTarefa3 = [];
            let arrValorTarefa4 = [];
            let arrValorTarefaTotal = [];

            if (num > 0) {
                for (let i = 0; i < num; i++) {

                    if (array[i] != undefined) {
                        const item = array[i];

                        const valorTarefa = item.valorTarefa != null && item.valorTarefa != 0 ? `${item.valorTarefa}` : '0';
                        const valorTarefa2 = item.valorTarefa2 != null && item.valorTarefa2 != 0 ? `${item.valorTarefa2}` : '0';
                        const valorTarefa3 = item.valorTarefa3 != null && item.valorTarefa3 != 0 ? `${item.valorTarefa3}` : '0';
                        const valorTarefa4 = item.valorTarefa4 != null && item.valorTarefa4 != 0 ? `${item.valorTarefa4}` : '0';
                        const valorTarefaTotal = item.valorTarefaTotal != null && item.valorTarefaTotal != 0 ? `${item.valorTarefaTotal}` : '0';
                        const valorPrevTarefa = item.valorPrevTarefa != null && item.valorPrevTarefa != 0 ? `${item.valorPrevTarefa}` : '0';
                        const letraTarefa = item.letraTarefa != null && item.letraTarefa != 0 ? `${item.letraTarefa}` : '';
                        const letraCustomizada = item.letraCustomizada != null && item.letraCustomizada != 0 ? `${item.letraCustomizada}` : '';

                        const jsonTamanhoLote = { id: i, valorTarefa: valorTarefa, valorPrevTarefa: valorPrevTarefa, letraTarefa: letraTarefa, letraCustomizada: letraCustomizada };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonValorTarefa1 = { id: i, valorTarefa: valorTarefa };
                        arrValorTarefa1.push(jsonValorTarefa1);

                        const jsonValorTarefa2 = { id: i, valorTarefa2: valorTarefa2 };
                        arrValorTarefa2.push(jsonValorTarefa2);

                        const jsonValorTarefa3 = { id: i, valorTarefa3: valorTarefa3 };
                        arrValorTarefa3.push(jsonValorTarefa3);

                        const jsonValorTarefa4 = { id: i, valorTarefa4: valorTarefa4 };
                        arrValorTarefa4.push(jsonValorTarefa4);

                        const jsonValorTarefaTotal = { id: i, valorTarefaTotal: valorTarefaTotal };
                        arrValorTarefaTotal.push(jsonValorTarefaTotal);

                        const jsonTarefa = {
                            id: i,
                            value: arrayComTodasAsLetrasP[i].value,
                            label: arrayComTodasAsLetrasP[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    } else {
                        const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: arrayComTodasAsLetrasP[i].label };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonValorTarefa1 = { id: i, valorTarefa: '0' };
                        arrValorTarefa1.push(jsonValorTarefa1);

                        const jsonValorTarefa2 = { id: i, valorTarefa2: '0' };
                        arrValorTarefa2.push(jsonValorTarefa2);

                        const jsonValorTarefa3 = { id: i, valorTarefa3: '0' };
                        arrValorTarefa3.push(jsonValorTarefa3);

                        const jsonValorTarefa4 = { id: i, valorTarefa4: '0' };
                        arrValorTarefa4.push(jsonValorTarefa4);

                        const jsonValorTarefaTotal = { id: i, valorTarefaTotal: '0' };
                        arrValorTarefaTotal.push(jsonValorTarefaTotal);

                        const jsonTarefa = {
                            id: i,
                            value: arrayComTodasAsLetrasP[i].value,
                            label: arrayComTodasAsLetrasP[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    }
                }
            } else {
                for (let i = 0; i < 3; i++) {

                    const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: arrayComTodasAsLetrasP[i].label };
                    arrTamanhoLote.push(jsonTamanhoLote);

                    const jsonValorTarefa1 = { id: i, valorTarefa: '0' };
                    arrValorTarefa1.push(jsonValorTarefa1);

                    const jsonValorTarefa2 = { id: i, valorTarefa2: '0' };
                    arrValorTarefa2.push(jsonValorTarefa2);

                    const jsonValorTarefa3 = { id: i, valorTarefa3: '0' };
                    arrValorTarefa3.push(jsonValorTarefa3);

                    const jsonValorTarefa4 = { id: i, valorTarefa4: '0' };
                    arrValorTarefa4.push(jsonValorTarefa4);

                    const jsonValorTarefaTotal = { id: i, valorTarefaTotal: '0' };
                    arrValorTarefaTotal.push(jsonValorTarefaTotal);

                    const jsonTarefa = {
                        id: i,
                        value: arrayComTodasAsLetrasP[i].value,
                        label: arrayComTodasAsLetrasP[i].label,
                    }
                    arrTarefa.push(jsonTarefa);
                }
            }
            setArrayAlternacia(arrTamanhoLote);
            setArrayValorTarefa1(arrValorTarefa1);
            setArrayValorTarefa2(arrValorTarefa2);
            setArrayValorTarefa3(arrValorTarefa3);
            setArrayValorTarefa4(arrValorTarefa4);
            setArrayValorTarefaTotal(arrValorTarefaTotal);

        } catch (error) {
            console.log('Erro na classe GerarPdfLancarProducao metodo atualizaDadosGeralNaTela', error);
        }
    }

    const rarioOpcoes = async (val) => {
        setIdTipoLancamento(val);

        if (val == '1') {

            const arrTamLote = arrayAlternacia.map((item) => {
                return { ...item, valorTarefa: '0' };
            });
            setArrayAlternacia(arrTamLote);

            const arrValorTarefa1 = arrayValorTarefa1.map((item) => {
                return { ...item, valorTarefa: '0' };
            });
            setArrayValorTarefa1(arrValorTarefa1);

            const arrValorTarefa2 = arrayValorTarefa2.map((item) => {
                return { ...item, valorTarefa2: '0' };
            });
            setArrayValorTarefa2(arrValorTarefa2);

            const arrValorTarefa3 = arrayValorTarefa3.map((item) => {
                return { ...item, valorTarefa3: '0' };
            });
            setArrayValorTarefa3(arrValorTarefa3);

            const arrValorTarefa4 = arrayValorTarefa4.map((item) => {
                return { ...item, valorTarefa4: '0' };
            });
            setArrayValorTarefa4(arrValorTarefa4);

            const arrValorTotal = arrayValorTarefaTotal.map((item) => {
                return { ...item, valorTarefaTotal: '0' };
            });
            setArrayValorTarefaTotal(arrValorTotal);

        } else {
            atualizaDadosGeralNaTela(alternanciaSelecionado, arrayDadosGeralCompleto.listProducaoPessoaAlternanciaItem, arrayComTodasAsLetras);
        }
    }

    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 1500);
    }

    const gerarPDF = async () => {
        const input = pdfRef.current;

        await html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('portrait', 'mm', 'a4', true);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeigh = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeigh = canvas.height;
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeigh / imgHeigh);
            pdf.addImage(imgData, 'PNG', 5, 10, pdfWidth - 10, imgHeigh * ratio);
            pdf.save(`${nomeRelatorio}.pdf`);
        });
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderIcones() {
        return (
            <Box bg={'transparent'}>
                <Flex px='20px' justify='flex-end' mb='3px' align='center'>
                    <Image ml={'20px'} cursor={'pointer'} boxSize='35px' objectFit='contain' src={PdfIcon} alt='Dan Abramov' onClick={downloadPdf} />
                </Flex>
            </Box>
        )
    }

    function renderInfo() {
        return (
            <Box my={'10px'} >
                <Wrap spacing='10px' mb={'5px'} justify='space-evenly' px={'1'} mx={'10px'} bgGradient={GRADIENTE} borderRadius={'10px'}>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Seringueiro'}</Text>
                            <Text mb={marginText} color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'}>{nomeSeringueiro}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Mês/Ano'}</Text>
                            <Text mb={marginText} color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'} textTransform={'capitalize'}>{descMesAno}</Text>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRario() {
        return (
            <Box
                pointerEvents={'none'} 
                opacity={0.5}
                mt={'40px'} 
                display={{ md: 'flex' }} 
                alignItems="center" 
                justifyContent="space-between" >
                <RadioGroup onChange={rarioOpcoes} value={idTipoLancamento} defaultValue='2'>
                    <Stack spacing={5} direction='row'>
                        <Radio size='md' name='1' colorScheme='blue' value='1' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir por Total</Text>
                        </Radio>
                        <Radio size='md' name='1' colorScheme='blue' value='2' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir por Tarefa/Lote</Text>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderValoresAlternancia() {
        const wid2 = '80px';
        const widthX = '150px';
        const colorBorder = 'blackAlpha.500';
        const pyValue = '5px';
        return (
            <TableContainer mt={'20px'}>
                <Table size='sm' variant='unstyled' overflowX="auto" whiteSpace="nowrap" >
                    <Thead>
                        <Tr>
                            <Th sx={{ paddingX: 0, paddingY: 0 }}>
                                <Text color={'white'} fontSize='11px' fontWeight='bold' align={'center'}>{''}</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td sx={{ paddingX: 0, paddingY: 0 }}>
                                <Flex flexDirection={'column'}>
                                    <Flex h={'35px'} justify='space-between' align='center' borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'} bgGradient={gradientHeader} >
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{''}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={wid2} justify='center' align='center' >
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{''}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center' >
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                    </Flex>
                                    <Flex h={'40px'} justify='space-between' align='center' bgGradient={gradientHeader}>
                                        <Box w={widthX}>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Tarefa/Lote'}</Text>
                                        </Box>
                                        <Box w={wid2} h={'100%'} bgGradient={gradientGray}>
                                            <Flex h={'100%'} justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Previsto'}</Text>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='14px' fontWeight='bold' align={'center'}>{dtValorTarefa}</Text>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='14px' fontWeight='bold' align={'center'}>{dtValorTarefa2}</Text>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='14px' fontWeight='bold' align={'center'}>{dtValorTarefa3}</Text>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='14px' fontWeight='bold' align={'center'}>{dtValorTarefa4}</Text>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX} h={'100%'} bgGradient={gradientGray}>
                                            <Flex h={'100%'} justify='center' align='center'>
                                                <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Total'}</Text>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Flex>
                                <Flex justify='space-between' align='center' mt={'0px'}>
                                    <Box w={widthX}>
                                        {arrayAlternacia.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.letraCustomizada}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={wid2}>
                                        {arrayAlternacia.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder} bgGradient={gradientGray}>
                                                    <Flex h={'30px'} justify='center' align='center' >
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorPrevTarefa}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa1.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorTarefa}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa2.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorTarefa2}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa3.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorTarefa3}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa4.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorTarefa4}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefaTotal.map((item, index) => {
                                            return (
                                                <Box key={index} py={pyValue} borderBottomWidth={'1px'} borderColor={colorBorder} bgGradient={gradientGray}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text mb={marginText} color={'orange.700'} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorTarefaTotal}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Flex>
                                <Flex h={'45px'} mt={'0px'} justify='space-between' align='center' bgGradient={gradientHeader} borderRadius={'8px'}>
                                    <Box w={widthX}>
                                        <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Total'}</Text>
                                    </Box>


                                    <Box w={wid2} h={'100%'} bgGradient={gradientGray}>
                                        <Flex h={'100%'} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalPrevMes}</Text>
                                        </Flex>
                                    </Box>

                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalValorTarefa1}</Text>
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalValorTarefa2}</Text>
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalValorTarefa3}</Text>
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalValorTarefa4}</Text>
                                        </Flex>
                                    </Box>
                                    <Box w={widthX} h={'100%'} bgGradient={gradientGray}>
                                        <Flex h={'100%'} justify='center' align='center'>
                                            <Text mb={marginText} color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalValorGeral}</Text>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'10px'} mt={'20px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box mt={'50px'}>
            <Card direction='column' px='20px' py='10px'>
                {renderAlert()}
                {renderIcones()}
                <Box ref={pdfRef} mt={'10px'} w='100%'>
                    <Box px={'5'}>
                        <Box position={'relative'}>
                            <Flex flexDirection={'column'} justify='center' align='center' mb={'20px'}>
                                <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>{`Lançamento Produção`}</Text>
                                <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeUa}</Text>
                                <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                            </Flex>
                            <Box position={'absolute'} top={'1px'} left={'10px'}>
                                <Image h='60px' w='180px' src={LogoHevea} alt='logo' />
                            </Box>
                        </Box>
                    </Box>
                    {renderInfo()}
                    {renderRario()}
                    {renderValoresAlternancia()}
                </Box>
                {renderButton()}
            </Card>
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />
            {loading && (<LoaderBlack isOpen={loading} />)}
        </Box>
    );
}
