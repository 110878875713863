import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Text,
    Flex,
    useColorModeValue,
    Input,
    Box,
} from "@chakra-ui/react";

function FormTabelasDeApoio({ isOpen, onPressCancel, onConfirm, titulo = 'OBS', valueInput = '' }) {
    const [inputValue, setInputValue] = useState(valueInput);
    const [isValidInput, setIsValidInput] = useState(false);
    const textColor = useColorModeValue("secondaryGray.900", "white");

    const handleConfirm = () => {
        if (inputValue.length < 1) {
            setIsValidInput(true);
        }else{
            setIsValidInput(false);
            if (onConfirm) {
                onConfirm(inputValue); // Envia o valor para o pai
            }
        }
    };

    const changeInput = (val) => {
        setInputValue(val);

        const isVal = val.length < 1 ? true : false;
        setIsValidInput(isVal);
    };

    const GRADIENTE = 'linear-gradient(to bottom, #d9d9d9, #ffffff, #d9d9d9)';

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => { }}
            isCentered
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size='xs'
        >
            <ModalOverlay />
            <ModalContent bgGradient={GRADIENTE}>
                <ModalHeader justifyContent={'center'} alignItems={'center'} py={'10px'}>
                    <Flex py={'10px'} justify={'center'} align={'center'}>
                        <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{titulo}</Text>
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Flex flexDirection={'column'} h={'100px'} justify={'start'} align={'center'}>
                        <Flex flexDirection={'column'} justify='center' align='center'>
                            <Box w={'100%'} >
                                <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' mb={'3px'} pl={'10px'}>Nome *</Text>
                            </Box>
                            <Input
                                value={inputValue}
                                onChange={(e) => changeInput(e.target.value)}
                                isInvalid={isValidInput}
                                errorBorderColor='crimson'
                                variant="filled"
                                borderRadius="10px"
                                borderColor={textColor}
                                textColor={textColor}
                                type="text"
                                fontSize={'13px'}
                                fontWeight={'bold'}
                                h={'35px'}
                                w={'250px'}
                                maxLength={50}
                            />
                        </Flex>
                        {isValidInput && <Text color={'red'} fontSize={'14px'} fontWeight={'light'}>{'Campo Obrigatório'}</Text>}
                    </Flex>
                </ModalBody>
                <ModalFooter mt={'20px'} borderTopWidth={'1px'} borderColor={'blackAlpha.700'} justifyContent={'space-around'}>
                    <Button w={'100px'} variant="action" onClick={onPressCancel}>Cancelar</Button>
                    <Button w={'100px'} variant="miniBrand" onClick={handleConfirm}>Salvar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default FormTabelasDeApoio;
