import React, { useState, useEffect } from 'react';
import {
    Flex,
    useColorModeValue,
    Button,
    Text,
    Input,
    SimpleGrid,
    Box,
    Wrap,
    Radio,
    RadioGroup,
    Stack,
    Center,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import * as Api from 'store/ApiSpring';
import * as ActionTypes from '../../../../constants/ActionTypes';
import { mascaraCpf, mascaraTelefone, retiraMascaraMaterNumeros } from '../../../../util/Masck';
import { useHistory } from "react-router-dom";
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import TooltipIcon from 'components/tooltip/TooltipIcon';

export default function FormSeringueiroCadastro() {

    const history = useHistory();
    const [isValidNome, setIsValidNome] = useState(false);
    const [inputTelefoneFixo, setInputTelefoneFixo] = useState('');
    const [operacao, setOperacao] = useState(ActionTypes.OPERACAO_INSERT);

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [imputCpf, setImputCpf] = useState('');
    const [isValidCpf, setIsValidCpf] = useState(false);
    const [impuNomeSeringueiro, setImpuNomeSeringueiro] = useState('');
    const [radioSexoP, setRadioSexoP] = useState('MASCULINO');
    const [idPessoaP, setIdPessoaP] = useState(null);
    const [nomeFazenda, setNomeFazenda] = useState('');

    const [idUa, setIdUa] = useState(null);
    const [pessoaAtivaP, setPessoaAtivaP] = useState(true);

    const [valueImputCelular, setValueImputCelular] = useState('');
    const [isValidCelular, setIsValidCelular] = useState(false);
    const [valueImputEmail, setValueImputEmail] = useState('');
    const [messageCardInformacao, setMessageCardInformacao] = useState('');
    const [listaUa, setListaUa] = useState([]);

    const [mensageAlert, setMensageAlert] = useState('');
    const [mensageAlertTitulo, setMensageAlertTitulo] = useState('Ok!');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [isCelularOrCpfJaExiste, setisCelularOrCpfJaExiste] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);
            const dadosProdutor = localStorage.getItem('@HE-datosParaEdicaoSeringueiroCadastro');
            const param = JSON.parse(dadosProdutor);

            setNomeFazenda(param.nomeFazenda);
            setIdUa(param.idUa);

            const operacaoX = param.operacao;
            if (operacaoX == ActionTypes.OPERACAO_UPDATE) {
                setOperacao(ActionTypes.OPERACAO_UPDATE);
                localStorage.removeItem('@HE-datosParaEdicaoSeringueiroCadastro');

                const idPessoa = param.idPessoa;
                setIdPessoaP(idPessoa);

                const url = `api/pessoa/retonaPessoaComUaAndSafra?idPessoa=${idPessoa}`;
                const response = await Api.getRequest(url);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    const objPessoa = res;
                    setImpuNomeSeringueiro(objPessoa.nome != null && objPessoa.nome != '' ? objPessoa.nome : '');
                    setImputCpf(objPessoa.cpf != null && objPessoa.cpf != '' ? mascaraCpf(objPessoa.cpf) : '');
                    setInputTelefoneFixo(objPessoa.telefoneFixo != null && objPessoa.telefoneFixo != '' ? mascaraTelefone(objPessoa.telefoneFixo) : '');
                    setRadioSexoP(objPessoa.sexo != null && objPessoa.sexo != '' ? objPessoa.sexo : 'MASCULINO');
                    setPessoaAtivaP(objPessoa.ativo != null && objPessoa.ativo != '' ? objPessoa.ativo : false);
                    setValueImputEmail(objPessoa.emailPessoal != null && objPessoa.emailPessoal != '' ? objPessoa.emailPessoal : '');
                    setIdUa(objPessoa.idUa != null ? objPessoa.idUa : null)
                    const numCelular = objPessoa.celular != null && objPessoa.celular != '' ? objPessoa.celular : '';
                    setValueImputCelular(numCelular != '' ? mascaraTelefone(numCelular) : '');

                    const listaUaP = objPessoa.listaUa;
                    if (listaUaP.length > 0) {
                        setListaUa(listaUaP);
                        setMessageCardInformacao('');
                    } else {
                        setMessageCardInformacao('Seringueiro não associado a nenhuma programação ou safra ativa');
                    }

                    setTelaCarregada(true);
                }
            } else {
                setMessageCardInformacao('Novo cadastro');
                setOperacao(ActionTypes.OPERACAO_INSERT);
                setTelaCarregada(true);
            }
        }
        loaderScreen();
    }, [])

    const salvarSeringueiro = async () => {
        try {
            setisCelularOrCpfJaExiste(false);
            let valid = false;

            if (impuNomeSeringueiro.trim().length < 3) {
                setIsValidNome(true);
                valid = true;
            } else {
                setIsValidNome(false);
            }
            if (imputCpf.trim().length < 14) {
                setIsValidCpf(true);
                valid = true;
            } else {
                setIsValidCpf(false);
            }
            if (valueImputCelular.length < 15) {
                setIsValidCelular(true);
                valid = true;
            } else {
                setIsValidCelular(false);
            }

            if (valid) return;

            const permissoesSeringueiro = [
                { idPermissao: 1 },
                { idPermissao: 3 },
            ]

            const cpfSemMascara = retiraMascaraMaterNumeros(imputCpf);
            const fixoSemMascara = retiraMascaraMaterNumeros(inputTelefoneFixo);
            const celularSemMascara = retiraMascaraMaterNumeros(valueImputCelular);
            const emailP = `sangrador${celularSemMascara}@hevea.com`;
            const nomeTrim = impuNomeSeringueiro.trim();

            const jsonPessoa = {
                idPessoa: idPessoaP,
                idUa: idUa,
                nome: nomeTrim,
                celular: celularSemMascara,
                ativo: pessoaAtivaP,
                urlImagemPerfil: null,
                dtCadastro: null,       // ESSE CARA PASSO NO BACK
                idTipoPessoa: ActionTypes.ID_TIPO_PESSOA_SANGRADOR,
                sexo: radioSexoP == 'MASCULINO' ? ActionTypes.SEXO_MASCULINO : ActionTypes.SEXO_FEMININO,
                cpf: cpfSemMascara,
                telefoneFixo: fixoSemMascara,
                email: emailP,
                emailPessoal: valueImputEmail,
                senha: '123456',
                idTipoUsuario: ActionTypes.TIPO_USUARIO_SERINGUEIRO,  // ESSE CARA PASSO NO BACK
                permissoes: permissoesSeringueiro,
            }

            if (operacao == ActionTypes.OPERACAO_UPDATE) {
                const response = await Api.putRequest(`api/pessoa/alterarSomentePessoa`, jsonPessoa);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    let valida = false;
                    let mensagem = 'Verifique os campos alterados:';

                    if (res.numeroCelularJaExiste == true || res.numeroCpfJaExiste == true) {

                        if (res.numeroCelularJaExiste == true) {
                            setIsValidCelular(true);
                            valida = true;
                            mensagem = `${mensagem}\nO campo celular foi alterado, mas o novo número informado já existe!`;
                        }
                        if (res.numeroCpfJaExiste == true) {
                            setIsValidCpf(true);
                            valida = true;
                            mensagem = `${mensagem} CPF já existe!`;
                        }
                        if (valida) {
                            setisCelularOrCpfJaExiste(true);
                            setMensageAlert(`${mensagem}`);
                            setMensageAlertTitulo('Ops!');
                            setOpenModalConfimacao(true);
                        }
                    } else {
                        setMensageAlert('Operação realizada com sucesso!');
                        setOpenModalConfimacao(true);
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                }
            } else {
                const response = await Api.postRequest(`api/pessoa/cadastrarSomentePessoa`, jsonPessoa);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    let valida = false;
                    let mensagem = 'Verifique os campos:\n';

                    if (res.numeroCelularJaExiste == true || res.numeroCpfJaExiste == true) {

                        if (res.numeroCelularJaExiste == true) {
                            setIsValidCelular(true);
                            valida = true;
                            mensagem = `${mensagem} Celular já existe!`;
                        }
                        if (res.numeroCpfJaExiste == true) {
                            setIsValidCpf(true);
                            valida = true;
                            mensagem = `${mensagem} CPF já existe!`;
                        }
                        if (valida) {
                            setisCelularOrCpfJaExiste(true);
                            setMensageAlert(`${mensagem}`);
                            setMensageAlertTitulo('Ops!');
                            setOpenModalConfimacao(true);
                        }
                    } else {
                        setMensageAlert('Operação realizada com sucesso!');
                        setOpenModalConfimacao(true);
                    }
                } else {
                    setMensageAlert('Erro no Processamento da Operação!');
                }
            }
        } catch (error) {
            console.log('Erro na classe FormSeringueiroCadastro metodo salvarSeringueiro', error);
        }
    }

    const changeCelular = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setValueImputCelular(telComMascara);
    }

    const changeTelefonefixo = (e) => {
        const tel = e.target.value;
        const telComMascara = mascaraTelefone(tel);
        setInputTelefoneFixo(telComMascara);
    }

    const changeCpf = (e) => {
        const tel = e.target.value;
        const val = mascaraCpf(tel);
        setImputCpf(val);
    }

    const closeAndGoBack = () => {
        setOpenModalConfimacao(false);

        if (operacao != ActionTypes.OPERACAO_UPDATE) {
            if(!isCelularOrCpfJaExiste){
                history.goBack();
            }
        }
    }

    function renderInputs() {
        return (
            <Box w={'100%'}>
                <Flex px='25px' mb={'10px'} justify='start' align='center'>
                    <Text color={textColor} fontSize='17px' fontWeight='700' lineHeight='100%'>Propriedade:</Text>
                    <Text ml={'2'} color={'orange.700'} fontSize='17px' fontWeight='700' lineHeight='100%'>{nomeFazenda}</Text>
                </Flex>
                <Card py='10px' px='20px' borderColor={'blackAlpha.600'} borderWidth={'1px'} >
                    <Box w={'100%'}>
                        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                            <Flex flexDirection={'column'} justify='center' align='center'>
                                <Box w={'100%'}>
                                    <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Nome *</Text>
                                </Box>
                                <Input
                                    value={impuNomeSeringueiro}
                                    onChange={(e) => setImpuNomeSeringueiro(e.target.value)}
                                    isInvalid={isValidNome}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                    borderRadius="10px"
                                    id='name'
                                    name='name'
                                    type="text"
                                    autoComplete='name'
                                    borderColor={textColor}
                                    textColor={textColor}
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Flex>
                            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                                <Flex flexDirection={'column'} justify='center' align='center'>
                                    <Box w={'100%'} >
                                        <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>CPF *</Text>
                                    </Box>
                                    <Input
                                        value={imputCpf}
                                        onChange={changeCpf}
                                        isInvalid={isValidCpf}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        placeholder=""
                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        type="text"
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        h={'35px'}
                                    />
                                </Flex>
                                <Flex flexDirection={'column'} justify='center' align='center'>
                                    <Box w={'100%'}>
                                        <Center justifyContent={'flex-start'} alignItems={'center'}>
                                            <Text px={'10px'} color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%'>Celular *</Text>
                                            <TooltipIcon
                                                label={
                                                    <Box px={'0px'} py={'5px'}>
                                                        <Text align={'center'}>{'Celular'}</Text>
                                                        <Text align={'start'} mt={'5px'}>{'Observação:'}</Text>
                                                        <Text align={'start'} mt={'10px'} ml={'10px'} lineHeight={'100%'}>{'O telefone cadastrado, também é o login do seringueiro para acessar ao sistema'}</Text>
                                                    </Box>
                                                }
                                                height={'18px'} />
                                        </Center>
                                    </Box>
                                    
                                    <Input
                                        value={valueImputCelular}
                                        onChange={changeCelular}
                                        isInvalid={isValidCelular}
                                        errorBorderColor='crimson'
                                        variant="filled"
                                        placeholder=""
                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        id='tel'
                                        name='tel'
                                        type="tel"
                                        autoComplete='tel'
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        h={'35px'}

                                    />
                                </Flex>
                            </SimpleGrid>
                        </SimpleGrid>
                    </Box>
                    <Box w={'100%'}>
                        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
                            <Flex flexDirection={'column'} justify='center' align='center'>
                                <Box w={'100%'} >
                                    <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>E-mail</Text>
                                </Box>
                                <Input
                                    value={valueImputEmail}
                                    onChange={(e) => setValueImputEmail(e.target.value)}
                                    errorBorderColor='crimson'
                                    variant="filled"
                                    placeholder=""
                                    borderRadius="10px"
                                    borderColor={textColor}
                                    textColor={textColor}
                                    id='email'
                                    name='email'
                                    type="email"
                                    autoComplete='email'
                                    fontSize={'13px'}
                                    fontWeight={'bold'}
                                    h={'35px'}
                                />
                            </Flex>
                            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                                <Flex flexDirection={'column'} justify='center' align='center'>
                                    <Box w={'100%'} >
                                        <Text color={textColor} fontSize='13px' fontWeight={'bold'} lineHeight='100%' p={'5px'}>Telefone Fixo</Text>
                                    </Box>
                                    <Input
                                        value={inputTelefoneFixo}
                                        onChange={changeTelefonefixo}
                                        variant="filled"
                                        placeholder=""
                                        _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                        borderRadius="10px"
                                        borderColor={textColor}
                                        textColor={textColor}
                                        type="text"
                                        fontSize={'13px'}
                                        fontWeight={'bold'}
                                        h={'35px'}
                                    />
                                </Flex>
                            </SimpleGrid>
                        </SimpleGrid>
                    </Box>
                    <Box px={'20px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                        <RadioGroup onChange={setRadioSexoP} value={radioSexoP} defaultValue='2'>
                            <Stack spacing={5} direction='row'>
                                <Radio size='lg' name='1' colorScheme='blue' value='MASCULINO'>
                                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Masculino</Text>
                                </Radio>
                                <Radio size='lg' name='1' colorScheme='blue' value='FEMININO'>
                                    <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Feminino</Text>
                                </Radio>
                            </Stack>
                        </RadioGroup>
                    </Box>
                </Card>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' w='100%' px='10px'>
                {renderInputs()}
                {/* {renderDadosSeringueiro()} */}
                <Wrap px={'20px'} py={'20px'} spacing='30px' justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button variant="brand" onClick={salvarSeringueiro}>Salvar</Button>
                </Wrap>
            </Card>
            {openModalConfimacao && <ModalConfirmacao titulo={mensageAlertTitulo} descricao={mensageAlert} isOpen={openModalConfimacao} onPress={() => { closeAndGoBack() }} />}
        </Box>
    );
}
