import React, { useEffect, useState, useRef } from "react";
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    TableContainer,
    Box,
    Center,
    Image,
    Button,
    Wrap,
    SimpleGrid,
    Stat,
    StatNumber,
    StatLabel,
    Checkbox,
} from "@chakra-ui/react";
import moment from 'moment';
import Card from "components/card/Card";
import ReactApexChart from "react-apexcharts";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import LoaderBlack from 'components/loading/LoaderBlack';
import { useHistory } from "react-router-dom";
import { retornaNomeDoMes } from '../../../../util/FuncoesUteis';
import { SALVAR_RELATORIO_PDF_SERVIDOR } from '../../../../util/SalvarImagem';
import LogoHevea from 'assets/img/dashboards/logo_hevea.png';
import * as ActionTypes from '../../../../constants/ActionTypes';

const jsonPers = {
    opcaoPendenciasAcoesDeCampo: true,
    opcaoObservacoesDiversasComentario: true,
    opcaoSangrias: true,
    opcaoQualidade: true,
    opcaoProducao: true,
}

export default function GerarRelatorioPersonalizado() {

    const pdfRef1 = useRef();
    const pdfRef2 = useRef();
    const pdfRef3 = useRef();
    const pdfRef4 = useRef();
    const pdfRef5 = useRef();

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [marginText, setMarginText] = useState('0px');
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [extratoSeringueiro, setExtratoSeringueiro] = useState([]);
    const [totalQtdTarefasSangraveis, setTotalQtdTarefasSangraveis] = useState(0);
    const [totalQtdTarefasSangradas, setTotalQtdTarefasSangradas] = useState(0);
    const [totalQtdTarefasNaoRecuperadas, setTotalQtdTarefasNaoRecuperadas] = useState(0);
    const [totalTarefasNaoSangradasPorChuva, setTotalTarefasNaoSangradasPorChuva] = useState(0);
    const [totalPercentPossiveisRealizadas, setTotalPercentPossiveisRealizadas] = useState(0);
    const [totalPercentIndiceficiencia, setTotalPercentIndiceficiencia] = useState(0);
    const [totalDiasNaoSangradosPorChuva, setTotalDiasNaoSangradosPorChuva] = useState(0);
    const [totalTotalFaltaslicencas, setTotalTotalFaltaslicencas] = useState(0);
    const [totalPercentDeAusenciasEmRelacaoAoTotal, setTotalPercentDeAusenciasEmRelacaoAoTotal] = useState(0);

    const [listGraficoSangradas, setListGraficoSangradas] = useState([]);
    const [listGraficoSangraveis, setListGraficoSangraveis] = useState([]);
    const [listGraficoEficiencia, setListGraficoEficiencia] = useState([]);

    const [listGraficoRealizado, setListGraficoRealizado] = useState([]);
    const [listGraficoPrevisto, setListGraficoPrevisto] = useState([]);
    const [listGraficoEficienciaProd, setListGraficoEficienciaProd] = useState([]);

    const [nomeFazenda, setNomeFazenda] = useState('');
    const [nomeSafra, setNomeSafra] = useState('');
    const [nomePessoaProducao, setNomePessoaProducao] = useState('Todos');
    const [nomePessoaSangria, setNomePessoaSangria] = useState('Todos');

    const [totalArvoresSangradas, setTotalArvoresSangradas] = useState(0);
    const [totalDistribuicao, setTotalDistribuicao] = useState(0);
    const [totalPrevisto, setTotalPrevisto] = useState(0);
    const [totalRealizado, setTotalRealizado] = useState(0);
    const [totalPercentual, setTotalPercentual] = useState(0);
    const [extratoProducao, setExtratoProducao] = useState([]);

    const [arrayAssunto, setarrayAssunto] = useState([]);
    const [arrayImagensSangria, setArrayImagensSangria] = useState([]);
    const [arrayImagensQualidade, setArrayImagensQualidade] = useState([]);
    const [arrayImagensProducao, setArrayImagensProducao] = useState([]);

    const [idUf, setIdUf] = useState('');
    const [nomeMunicipio, setNomeMunicipio] = useState('');
    const [nomeMesCorrente, setNomeMesCorrente] = useState('');
    const [comentarioObservacao, setComentarioObservacao] = useState('');
    const [comentarioSangria, setComentarioSangria] = useState('');
    const [comentarioQualidade, setComentarioQualidade] = useState('');
    const [comentarioProducao, setComentarioProducao] = useState('');
    const [arrayQu, setArrayQu] = useState([]);
    const [mediaGeralDePontos, setMediaGeralDePontos] = useState(0);
    const [notaGeral, setNotaGeral] = useState(0);
    const [avaliacaoGeral, setAvaliacaoGeral] = useState('');
    const [corNotaGeral, setCorNotaGeral] = useState('');

    const [objOpcoesPersonalizado, setObjOpcoesPersonalizado] = useState(jsonPers);
    const [gerarCopiaPdfServidor, setGerarCopiaPdfServidor] = useState(false);
    const [nomeRelatorioSeringual, setNomeRelatorioSeringual] = useState('Relatorio-do-Seringal');
    const [imgLogoRelatorio, setImgLogoRelatorio] = useState(null);
    const [dimensions, setDimensions] = useState({ width: 180, height: 60 });

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorHeader = useColorModeValue("white", "white");
    const textColorTituloModulo = "orange.700";
    const titleSize = '22px';
    const colorFooterAndHeader = useColorModeValue("rgba(204, 90, 0, 0.9)", "black");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const authBg = useColorModeValue("whitesmoke", "navy.900");
    const borWidthItem = '0px';
    const borRadiostem = '15px';
    const borColortem = 'blackAlpha.300';
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientPercent = 'linear-gradient(orange.300, orange.200, orange.50)';
    const gradientFocus = 'linear-gradient(blackAlpha.500, blackAlpha.400, blackAlpha.300)';
    const gradientSubTotal = 'linear-gradient(#ff8000, #ff9933, #ffcc99)';

    useEffect(() => {
        async function loadScreen() {
            try {
                window.scrollTo(0, 0);

                const imgStr = localStorage.getItem('@HE-imagemDaLogoDoUsuarioTecnico');
                const imgFile = imgStr != null ? JSON.parse(imgStr) : [];

                if(imgFile.length > 0){
                    const xxh1 = imgFile[0];
                    setImgLogoRelatorio(xxh1.fileBase64);

                    const widthP = xxh1.width != null && xxh1.width != undefined ? xxh1.width : 180;
                    const heightP = xxh1.height != null && xxh1.height != undefined ? xxh1.height : 60;
                    setDimensions({ width: widthP, height: heightP });

                }else{
                    setImgLogoRelatorio(LogoHevea);
                    setDimensions({ width: 180, height: 60 });
                }

                // const xxh1 = imgFile.length > 0 ? imgFile[0].fileBase64 : LogoHevea;
                // setImgLogoRelatorio(xxh1);

                const objPer = localStorage.getItem('@HE-opcoes-relatorio-personalizado');
                const objOpcoesPersonalizadoP = JSON.parse(objPer);
                setObjOpcoesPersonalizado(objOpcoesPersonalizadoP);

                const arraySang = localStorage.getItem('@HE-array-imagens-sangria-personalizado');
                const zzS = arraySang != null ? JSON.parse(arraySang) : [];
                setArrayImagensSangria(zzS);

                const arrayQuali = localStorage.getItem('@HE-array-imagens-qualidade-personalizado');
                const zzQ = arrayQuali != null ? JSON.parse(arrayQuali) : [];
                setArrayImagensQualidade(zzQ);

                const arrayProd = localStorage.getItem('@HE-array-imagens-producao-personalizado');
                const zzk = arrayProd != null ? JSON.parse(arrayProd) : [];
                setArrayImagensProducao(zzk);

                const comSa = localStorage.getItem('@HE-comentario-tela-sangria');
                const comentarioSangriaP = comSa != null && comSa != '' ? comSa : '';
                setComentarioSangria(comentarioSangriaP);

                const comQuali = localStorage.getItem('@HE-comentario-tela-qualidade');
                const comentarioQualidadeP = comQuali != null && comQuali != '' ? comQuali : '';
                setComentarioQualidade(comentarioQualidadeP);

                const comPro = localStorage.getItem('@HE-comentario-tela-producao');
                const comentarioProducaoP = comPro != null && comPro != '' ? comPro : '';
                setComentarioProducao(comentarioProducaoP);

                const idUfP = localStorage.getItem('@HE-idUfCadastradadoNaUa');
                const nomeMunicipioP = localStorage.getItem('@HE-idNomeUfCadastradadoNaUa');
                setIdUf(idUfP);
                setNomeMunicipio(nomeMunicipioP);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const descData = `${nmMes}/${ano}`;
                setNomeMesCorrente(descData);

                const nmRelatorio = `Relatorio-do-Seringal-${dia}-${nmMes}-${ano}`;
                setNomeRelatorioSeringual(nmRelatorio);

                const nomeUa = localStorage.getItem('@HE-nomeUa');
                setNomeFazenda(nomeUa);
                const nmSafra = localStorage.getItem('@HE-nomeSafra');
                setNomeSafra(nmSafra);

                // ASSUNTO
                let arrayAssuntoP = [];
                const arr = localStorage.getItem('@HE-array-assunto');
                if (arr != null) {
                    arrayAssuntoP = JSON.parse(arr);
                    arrayAssuntoP.sort(function (a, b) { return a.idAssunto - b.idAssunto });
                    setarrayAssunto(arrayAssuntoP);
                }

                // COMENTARIOS
                const com = localStorage.getItem('@HE-comentario-tela-observacoes');
                const obsComentarios = com != null && com != '' ? com : '';
                setComentarioObservacao(obsComentarios);

                // SANGRIA
                const san = localStorage.getItem('HE@-sangriasDiarias-personalizado');
                const ARR_SAN = JSON.parse(san);

                const totalQtdTarefasSangraveisX = ARR_SAN.totalQtdTarefasSangraveis != null ? ARR_SAN.totalQtdTarefasSangraveis : 0;
                setTotalQtdTarefasSangraveis(totalQtdTarefasSangraveisX);
                setTotalQtdTarefasSangradas(ARR_SAN.totalQtdTarefasSangradas);
                setTotalQtdTarefasNaoRecuperadas(ARR_SAN.totalQtdTarefasNaoRecuperadas);
                setTotalTarefasNaoSangradasPorChuva(ARR_SAN.totalTarefasNaoSangradasPorChuva);
                setTotalPercentPossiveisRealizadas(ARR_SAN.totalPercentPossiveisRealizadas);
                setTotalPercentIndiceficiencia(ARR_SAN.totalPercentIndiceficiencia);
                setTotalDiasNaoSangradosPorChuva(ARR_SAN.totalDiasNaoSangradosPorChuva);
                setTotalTotalFaltaslicencas(ARR_SAN.totalTotalFaltaslicencas);
                setTotalPercentDeAusenciasEmRelacaoAoTotal(ARR_SAN.totalPercentDeAusenciasEmRelacaoAoTotal);
                setNomePessoaSangria(ARR_SAN.nomePessoa);

                setExtratoSeringueiro(ARR_SAN.extratoSeringueiro);
                const arrGrafc = ARR_SAN.arraysGrafico;

                setListGraficoSangradas(arrGrafc.listGraficoSangradas);
                setListGraficoSangraveis(arrGrafc.listGraficoSangraveis);
                setListGraficoEficiencia(arrGrafc.listGraficoEficiencia);

                // QUALIDADE
                const qua = localStorage.getItem('@HE-qualidadeSangria-personalizado');
                const ARR_QUA = JSON.parse(qua);

                const arrayQualidadeX = ARR_QUA.arrayQu != null ? ARR_QUA.arrayQu : [];
                const mediaGeralDePontosX = ARR_QUA.mediaGeralDePontos != null ? ARR_QUA.mediaGeralDePontos : 0;
                const notaGeralX = ARR_QUA.notaGeral != null ? ARR_QUA.notaGeral : 0;
                const avaliacaoGeralX = ARR_QUA.avaliacaoGeral != null ? ARR_QUA.avaliacaoGeral : '';
                const corNotaGeralX = ARR_QUA.corNotaGeral != null ? ARR_QUA.corNotaGeral : "secondaryGray.900";

                setArrayQu(arrayQualidadeX);
                setMediaGeralDePontos(mediaGeralDePontosX);
                setNotaGeral(notaGeralX);
                setAvaliacaoGeral(avaliacaoGeralX);
                setCorNotaGeral(corNotaGeralX);

                // PRODUCAO
                const prod = localStorage.getItem('HE@-dadosRelatorioAcompanhamentoProducao');
                const ARR_PROD = JSON.parse(prod);

                setTotalArvoresSangradas(ARR_PROD.totalArvoresSangradas);
                setTotalDistribuicao(ARR_PROD.totalDistribuicao);
                setTotalPrevisto(ARR_PROD.totalPrevisto);
                setTotalRealizado(ARR_PROD.totalRealizado);
                setTotalPercentual(ARR_PROD.totalPercentual);
                setExtratoProducao(ARR_PROD.extratoProducao);
                setNomePessoaProducao(ARR_PROD.nomePessoa);

                const arrGrafcProd = ARR_PROD.arraysGraficoProdcao;

                setListGraficoRealizado(arrGrafcProd.listGraficoRealizado);
                setListGraficoPrevisto(arrGrafcProd.listGraficoPrevisto);
                setListGraficoEficienciaProd(arrGrafcProd.listGraficoEficiencia);

                setTelaCarregada(true);
            } catch (error) {
                console.log('Erro na classe GerarRelatorioPersonalizado metodo useEffect', error);
            }
        }
        loadScreen();
    }, []);


    const downloadPdf = async () => {
        setLoading(true);
        setMarginText('15px');

        setTimeout(async () => {
            await gerarPDF();
            setMarginText('0px');
            setLoading(false);
        }, 2000);
    }

    /*
    ╔═══════════════════════════════════════════════════════════════════════════╗
        
    ╚═══════════════════════════════════════════════════════════════════════════╝*/
    const gerarPDF = async () => {

        const input1 = pdfRef1.current;
        let imgData1 = null;
        let imgHeigh1 = 0;
        let imgWWIDTH1 = 0;
        await html2canvas(input1).then((canvas) => {
            imgData1 = canvas.toDataURL('image/png');
            imgHeigh1 = canvas.height;
            imgWWIDTH1 = canvas.width;          // ESSE CARA E O MESMO VALOR PRA TODOS
        });
        const pdf = new jsPDF('portrait', 'mm', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeigh = pdf.internal.pageSize.getHeight();
        const ratio1 = Math.min(pdfWidth / imgWWIDTH1, pdfHeigh / imgHeigh1);

        pdf.addImage(imgData1, 'PNG', 5, 10, pdfWidth - 10, imgHeigh1 * ratio1);

        let imgData2 = null;
        let imgHeigh2 = 0;

        let imgData3 = null;
        let imgHeigh3 = 0;

        let imgData4 = null;
        let imgHeigh4 = 0;

        let imgData5 = null;
        let imgHeigh5 = 0;

        if (objOpcoesPersonalizado.opcaoPendenciasAcoesDeCampo || objOpcoesPersonalizado.opcaoObservacoesDiversasComentario) {
            const input2 = pdfRef2.current;
            await html2canvas(input2).then((canvas) => {
                imgData2 = canvas.toDataURL('image/png');
                imgHeigh2 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData2, 'PNG', 5, 10, pdfWidth - 10, imgHeigh2 * ratio1);
            });
        }

        if (objOpcoesPersonalizado.opcaoSangrias) {
            const input3 = pdfRef3.current;
            await html2canvas(input3).then((canvas) => {
                imgData3 = canvas.toDataURL('image/png');
                imgHeigh3 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData3, 'PNG', 5, 10, pdfWidth - 10, imgHeigh3 * ratio1);
            });
        }

        if (objOpcoesPersonalizado.opcaoQualidade) {
            const input4 = pdfRef4.current;
            await html2canvas(input4).then((canvas) => {
                imgData4 = canvas.toDataURL('image/png');
                imgHeigh4 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData4, 'PNG', 5, 10, pdfWidth - 10, imgHeigh4 * ratio1);
            });
        }

        if (objOpcoesPersonalizado.opcaoProducao) {
            const input5 = pdfRef5.current;
            await html2canvas(input5).then((canvas) => {
                imgData5 = canvas.toDataURL('image/png');
                imgHeigh5 = canvas.height;
                pdf.addPage('a4', 'portrait');
                pdf.addImage(imgData5, 'PNG', 5, 10, pdfWidth - 10, imgHeigh5 * ratio1);
            });
        }

        pdf.save(`${nomeRelatorioSeringual}.pdf`);

        if (gerarCopiaPdfServidor) {
            const blob = pdf.output('blob');
            SALVAR_RELATORIO_PDF_SERVIDOR(blob, ActionTypes.ID_TIPO_ANEXO_PERSONALIZADO_PDF);
        }
    }

    const series = [
        {
            name: 'Tarefas Sangráveis',
            type: 'column',
            data: listGraficoSangraveis
        },
        {
            name: 'Tarefas Sangradas',
            type: 'column',
            data: listGraficoSangradas
        },
        {
            name: 'Eficiência de Sangria',
            type: 'line',
            data: listGraficoEficiencia
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: false,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
            toolbar: {      // ESSE CARA MOSTRA OU OCULTA OS BOTOES DE DOLWNLOAD, ZOOM, HOME...
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ['#00004d', '#0099ff', '#ff0000'],
        stroke: {
            width: [0, 1, 0],
            curve: 'smooth'     // 'smooth', 'straight', 'stepline'
        },
        plotOptions: {
            bar: {
                enabled: false,
                dataLabels: {
                    enabled: false, // Define como false para ocultar as etiquetas de dados (colunas)
                }
            }
        },
        tooltip: {
            enabled: false,
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
            type: 'category',
            categories: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO'],
            position: 'bottom',
            labels: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                style: {
                    colors: "#000000",
                    fontSize: "11px",
                    fontWeight: "500",
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            horizontalAlign: 'center',
            fontWeight: 500,
            offsetX: 0,
            offsetY: 10,
            floating: false, // Mantem a legenda como parte do layout normal
            itemMargin: {
                horizontal: 20, // Aumenta o espaçamento entre os itens
                vertical: 5, // Ajusta a altura das legendas
            },
            markers: {
                width: 14, // Ajusta o tamanho do marcador
                height: 14,
            },

        },
        yaxis: [
            {
                show: false,
                seriesName: 'Tarefas Sangráveis',
            },
            {
                show: false,
                seriesName: 'Tarefas Sangráveis',
            },
            {
                show: false,
                seriesName: 'Eficiência de Sangria',
            },
        ],
        fill: {
            type: "gradient",
            colors: ['#00004d', '#0099ff', '#ff0000'],
        },
    }

    const seriesProducao = [
        {
            name: 'Previsto (kg)',
            type: 'column',
            data: listGraficoPrevisto
        },
        {
            name: 'Realizado (kg)',
            type: 'column',
            data: listGraficoRealizado
        },
        {
            name: '% Realizado',
            type: 'line',
            data: listGraficoEficienciaProd
        }
    ]

    const optionsProducao = {
        chart: {
            height: 350,
            type: 'line',
            foreColor: 'black',
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
            toolbar: {      // ESSE CARA MOSTRA OU OCULTA OS BOTOES DE DOLWNLOAD, ZOOM, HOME...
                show: false,    // OCULTA OU MOSTRA TODOS AS OPCOES
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            zoom: {
                enabled: false, // Desativa o zoom via scroll do mouse
            },
        },
        colors: ['#00004d', '#0099ff', '#ff0000'],
        stroke: {
            width: [0, 1, 0],
            curve: 'smooth'     // 'smooth', 'straight', 'stepline'
        },
        tooltip: {
            enabled: false,
        },
        title: {
            text: ''
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1, 2]
        },
        labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
        xaxis: {
            categories: ['SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO'],
            labels: {
                style: {
                    colors: "#4e6097",
                    fontSize: "13px",
                    fontWeight: "500",
                },
            },
        },
        legend: {
            show: true,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            horizontalAlign: 'center',
            fontWeight: 100,
            offsetX: 0,
            offsetY: 10,
            floating: false, // Mantem a legenda como parte do layout normal
            itemMargin: {
                horizontal: 20, // Aumenta o espaçamento entre os itens
                vertical: 5, // Ajusta a altura das legendas
            },
            markers: {
                width: 14, // Ajusta o tamanho do marcador
                height: 14,
            },
        },
        yaxis: [
            {
                show: false,
                seriesName: 'Previsto (kg)',
            },
            {
                show: false,
                seriesName: 'Previsto (kg)',
            },
            {
                show: false,
                seriesName: '% Realizado',
            },
        ],
        fill: {
            type: "gradient",
            colors: ['#00004d', '#0099ff', '#ff0000'],
        },
    }

    function renderAssunto() {
        const mbP = '2';
        const sizeF = '13px';
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                    <Thead px={'0px'} bg={colorFooterAndHeader}>
                        <Tr bgGradient={gradientHeader} h={'30px'}>
                            <Th w={'60px'}>
                                <Box textTransform={'none'} >
                                    <Text color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText}>Assunto</Text>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Sugestões Técnicas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Local'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Prazo'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Responsável'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {arrayAssunto.map((item, key) => {
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' mb={marginText}>{item.nomeAssunto}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{item.sugestoesTecnicas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{item.local}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{item.prazo}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{item.responsavel}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderCardsQualidade() {
        let mediaG = `${mediaGeralDePontos}`;
        mediaG = mediaG.length == 1 ? `${mediaG}.0` : mediaG;
        mediaG = mediaG.replace('.', ',');
        let notaG = `${notaGeral}`;
        notaG = notaG.length == 1 ? `${notaG}.0` : notaG;
        notaG = notaG.replace('.', ',');
        return (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'} px={'3'}>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Total de pontos'}</StatLabel>
                            <StatNumber color={textColor} fontSize={{ base: "xl", }}>{mediaG}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Nota dos seringueiro'}</StatLabel>
                            <StatNumber color={corNotaGeral} fontSize={{ base: "xl", }}>{`${notaG}%`}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
                <Card py='10px' align='center' justify={'start'} border={'2px'} borderRadius={'20px'} borderColor={'blackAlpha.400'}>
                    <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                        <Stat h={'100%'} my='auto' ms={"0px"}>
                            <StatLabel lineHeight='100%' color={textColor} fontSize={{ base: "md", }}>{'Avaliação da sangria'}</StatLabel>
                            <StatNumber color={corNotaGeral} fontSize={{ base: "xl", }}>{avaliacaoGeral}</StatNumber>
                        </Stat>
                    </Flex>
                </Card>
            </SimpleGrid>
        )
    }

    function renderTableQualidade() {
        const mbP = '2';
        const sizeF = '13px';
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha'>
                    <Thead px={'0px'} bg={colorFooterAndHeader}>
                        <Tr bgGradient={gradientHeader} h={'30px'}>
                            <Th w={'60px'}>
                                <Box textTransform={'none'} >
                                    <Text color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText}>Nome</Text>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Total de pontos'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Nota do seringueiro'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Avaliação'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {arrayQu.map((item, key) => {
                            let pontosS = `${item.totalPontos}`;
                            pontosS = pontosS.length == 1 ? `${pontosS}.0` : pontosS;
                            pontosS = pontosS.replace('.', ',');

                            let notaS = `${item.notaSeringueiro}`;
                            notaS = notaS.length == 1 ? `${notaS}.0` : notaS;
                            notaS = notaS.replace('.', ',');
                            return (
                                <Tr key={key + 1}>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' mb={marginText}>{item.nome}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{pontosS}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${notaS}%`}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{item.avaliacaoSangria}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderTableSangrias() {
        const sizeF = '13px';
        let corLinha = false;
        return (
            <TableContainer>
                <Table size='sm' variant='unstyled'>
                    <Thead px={'0px'} bg={'blackAlpha.500'}>
                        <Tr h={'60px'} bgGradient={gradientHeader}>
                            <Th w={'60px'}>
                                <Box textTransform={'none'} >
                                    <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'start'} >Mês</Text>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Possíveis'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'de Sangria'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Total de'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Realizadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Possíveis'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Realizadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Qtd tarefas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Não'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Recuperadas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Tarefas Não'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Sangradas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Por Chuva'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Eficiência'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'De Sangria'}</Text>
                                    </Center>
                                </Box>
                            </Th>

                            <Th w={'90px'}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Total de'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Faltas'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'E Licenças'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th w={'90px'} bgGradient={gradientFocus}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Ausências'}</Text>
                                    </Center>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} >{'Em Relação'}</Text>
                                    </Center>
                                    <Center>
                                        <Text mb={marginText} color={textColorHeader} fontSize={sizeF} align={'center'} >{'Ao Total'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {extratoSeringueiro.map((item, key) => {
                            corLinha = !corLinha;
                            return (
                                <Tr key={key + 1} bg={corLinha ? 'rgba(217, 217, 217, 0.2)' : 'white'} borderBottomWidth={'1px'} borderColor={'blackAlpha.400'}>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' mb={marginText}>{item.mes}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasSangraveis}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasSangradas}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.PossiveisRealizadas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.qtdTarefasNaoRecuperadas}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.tarefasNaoSangradasPorChuva}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.eficienciaDeSangria}</Text>
                                    </Td>
                                    <Td>
                                        <Text color={textColor} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.TotalDeFaltasAndLicencas}</Text>
                                    </Td>
                                    <Td bgGradient={gradientPercent}>
                                        <Text color={'red.800'} fontSize={sizeF} lineHeight={'100%'} fontWeight='700' align={'center'} mb={marginText}>{item.percentDeAusenciasEmRelacaoAoTotal}</Text>
                                    </Td>
                                </Tr>
                            )
                        })}
                        <Tr h={'30px'} alignItems={'center'} bgGradient={gradientHeader}>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText} >{'Total'}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasSangraveis}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasSangradas}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentPossiveisRealizadas}%`}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalQtdTarefasNaoRecuperadas}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalTarefasNaoSangradasPorChuva}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentIndiceficiencia}%`}</Text>
                            </Td>
                            <Td>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{totalTotalFaltaslicencas}</Text>
                            </Td>
                            <Td bgGradient={gradientFocus}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText} >{`${totalPercentDeAusenciasEmRelacaoAoTotal}%`}</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    function renderAcompanhamentoDaProducao() {
        const sizeF = '13px';
        const sizeSubTotal = '15px';
        const alturaLinha = 1;
        return (
            <TableContainer>
                <Table size='sm' variant='striped' colorScheme='blackAlpha' >
                    <Thead bg={colorFooterAndHeader}>
                        <Tr bgGradient={gradientHeader} h={'30px'}>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'}>
                                    <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} align={'start'} mb={marginText}>{'Mês'}</Text>
                                </Box>
                            </Th>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'} >
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Árvores Sangradas'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Distrib'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Previsto (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'Realizado (kg)'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                            <Th sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Box textTransform={'none'}>
                                    <Center>
                                        <Text color={textColorHeader} fontSize={sizeF} align={'center'} mb={marginText}>{'%'}</Text>
                                    </Center>
                                </Box>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {extratoProducao.map((item, key) => {
                            const ttParcial = item.totalAteMesAnterior;
                            return (
                                <Tr key={key + 1} bg={'transparent'} >
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} ml={'10px'} color={textColor} fontSize={sizeF} fontWeight='700' lineHeight={'100%'}>{item.mes}</Text>
                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='start' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} ml={'10px'} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700' align={'start'} >{'Total Parcial'}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.arvoresSangradas}</Text>

                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='center' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalArvoresSangradasAteMesAnterior}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.distribuicao}%`}</Text>

                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='center' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{`${ttParcial.totalDistribuicaoAteMesAnterior}%`}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.previsto}</Text>

                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='center' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalPrevistoAteMesAnterior}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{item.realizado}</Text>

                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='center' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{ttParcial.totalRealizadoAteMesAnterior}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                    <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                        <Text mb={marginText} color={textColor} fontSize={sizeF} fontWeight='700' align={'center'} lineHeight={'100%'}>{`${item.percentual}%`}</Text>

                                        {ttParcial != null &&
                                            <Flex w={'100%'} h={'30px'} mt={'0px'} mb='0px' justify='center' align='center' bgGradient={gradientSubTotal}>
                                                <Text mb={marginText} color={textColorHeader} fontSize={sizeSubTotal} fontWeight='700'>{`${ttParcial.totalPercentualAteMesAnterior}%`}</Text>
                                            </Flex>
                                        }
                                    </Td>
                                </Tr>
                            )
                        })}
                        <Tr bgGradient={gradientHeader}>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text ml={'10px'} color={textColorHeader} fontSize={sizeF} fontWeight='700' mb={marginText}>{'Total árvores em sangria'}</Text>
                            </Td>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalArvoresSangradas}</Text>
                            </Td>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalDistribuicao}%`}</Text>
                            </Td>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalPrevisto}</Text>
                            </Td>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{totalRealizado}</Text>
                            </Td>
                            <Td sx={{ paddingX: 0, paddingY: alturaLinha }}>
                                <Text color={textColorHeader} fontSize={sizeF} fontWeight='700' align={'center'} mb={marginText}>{`${totalPercentual}%`}</Text>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card direction='column' overflowX={{ sm: "scroll", lg: "hidden" }} w='100%' pt='0px' px='0px' borderRadius={'15px'} bg={authBg}>
                <Box ref={pdfRef1} w='100%' bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                    <Box position={'relative'}>
                        <Flex flexDirection={'column'} justify='center' align='center' mb={'20px'}>
                            <Text color={'orange.600'} fontSize='25px' fontWeight={'bold'}>Relatório do Seringal</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{nomeMesCorrente}</Text>
                            <Text color={'gray.500'} fontSize='15px' fontWeight={'bold'} lineHeight={'100%'}>{nomeFazenda}</Text>
                            <Text mb={marginText} color={'gray.500'} fontSize='15px' fontWeight={'bold'} >{`${nomeMunicipio}/${idUf}`}</Text>
                        </Flex>
                        <Box position={'absolute'} top={'1px'} left={'10px'}>
                            {/* <Image h='60px' w='180px' objectFit={'contain'} src={imgLogoRelatorio} alt='logo' /> */}
                            <Image h={dimensions.height} w={dimensions.width} objectFit={'contain'} src={imgLogoRelatorio} alt='logo' />
                        </Box>
                    </Box>
                    <Box h={'500px'} w='100%' px={'20px'}>
                        <Flex h={'100%'} flexDirection={'column'} justify={'center'}>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Sumário</Text>
                            {objOpcoesPersonalizado.opcaoPendenciasAcoesDeCampo && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>1 - Pendências e Ações de Campo</Text>}
                            {objOpcoesPersonalizado.opcaoObservacoesDiversasComentario && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>2 - Observações diversas e Comentários</Text>}
                            {objOpcoesPersonalizado.opcaoSangrias || objOpcoesPersonalizado.opcaoQualidade || objOpcoesPersonalizado.opcaoProducao && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'}>3 - Indicadores Mensais</Text>}
                            {objOpcoesPersonalizado.opcaoSangrias && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} ml={'10px'}>3.1 - Acompanhamento das Sangrias Diárias</Text>}
                            {objOpcoesPersonalizado.opcaoQualidade && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} ml={'10px'}>3.2 - Qualidade Sangria</Text>}
                            {objOpcoesPersonalizado.opcaoProducao && <Text color={textColor} fontSize={'13px'} fontWeight={'bold'} ml={'10px'}>3.3 - Acompanhamento da Produção 2023_24</Text>}
                        </Flex>
                    </Box>
                </Box>
                <Box ref={pdfRef2} w='100%' mt={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                    {objOpcoesPersonalizado.opcaoPendenciasAcoesDeCampo &&
                        <Box w='100%'>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Pendências e Ações de Campo</Text>
                            <Box w='100%' px={'2'} mt={'10px'}>
                                <Box mt='10px' bg={'white'} mb={'30px'}>
                                    {renderAssunto()}
                                </Box>
                            </Box>
                        </Box>
                    }
                    {objOpcoesPersonalizado.opcaoObservacoesDiversasComentario &&
                        <Box w='100%' mb={'0px'} px={'20px'}>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Observações diversas e Comentários</Text>
                            <Text color={textColor} fontSize={'15px'} fontWeight={'bold'}>{comentarioObservacao}</Text>
                        </Box>
                    }
                </Box>

                {objOpcoesPersonalizado.opcaoSangrias &&
                    <Box ref={pdfRef3} w='100%' mt={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                        <Box w='100%'>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Acompanhamento Diário das Sangrias</Text>
                            <Flex px='20px' justify='flex-start' align='center' >
                                <Text color={textColor} fontSize={'18px'} fontWeight={'light'}>Seringueiro:</Text>
                                <Text color={textColor} fontSize={'18px'} fontWeight={'bold'} ml={'10px'}>{nomePessoaSangria}</Text>
                            </Flex>
                            <Box w='100%' px={'2'} mt={'10px'}>
                                <Box mt='10px' bg={'white'} mb={'30px'}>
                                    {renderTableSangrias()}
                                </Box>
                                <Box h='380px' mt='30px' bg={'white'} borderWidth={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.400'}>
                                    <ReactApexChart options={options} series={series} type="line" height={350} width={'100%'} />
                                </Box>
                            </Box>
                            {comentarioSangria != '' &&
                                <Box mt={'3'} px={'5'}>
                                    <Text mb={marginText} color={textColor} fontSize={'18px'} fontWeight={'bold'}>Comentário</Text>
                                    <Box p={'2'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.300'}>
                                        <Text mb={marginText} color={'gray.500'} fontSize={'15px'}>{comentarioSangria}</Text>
                                    </Box>
                                </Box>
                            }
                            <Box mt={'5'} px={'5'}>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                                    {arrayImagensSangria.length > 0 &&
                                        arrayImagensSangria.map((item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Card py='10px' align='center' justify={'start'}>
                                                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                                                <Flex align='center' justify={'center'}>
                                                                    <Image boxSize='200px' objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                                </Flex>
                                                            </Stat>
                                                        </Flex>
                                                    </Card>
                                                </Box>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                            </Box>
                        </Box>
                    </Box>
                }

                {objOpcoesPersonalizado.opcaoQualidade &&
                    <Box ref={pdfRef4} w='100%' mt={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                        <Box w='100%'>
                            <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'} align={'center'}>Qualidade Sangria</Text>
                            {renderCardsQualidade()}
                            <Box w='100%' px={'2'} mt={'10px'}>
                                <Box mt='10px' bg={'white'} mb={'30px'}>
                                    {renderTableQualidade()}
                                </Box>
                            </Box>
                            {comentarioQualidade != '' &&
                                <Box mt={'3'} px={'5'}>
                                    <Text mb={marginText} color={textColor} fontSize={'18px'} fontWeight={'bold'}>Comentário</Text>
                                    <Box p={'2'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.300'}>
                                        <Text mb={marginText} color={'gray.500'} fontSize={'15px'}>{comentarioQualidade}</Text>
                                    </Box>
                                </Box>
                            }
                            <Box mt={'5'} px={'5'}>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                                    {arrayImagensQualidade.length > 0 &&
                                        arrayImagensQualidade.map((item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Card py='10px' align='center' justify={'start'}>
                                                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                                                <Flex align='center' justify={'center'}>
                                                                    <Image boxSize='200px' objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                                </Flex>
                                                            </Stat>
                                                        </Flex>
                                                    </Card>
                                                </Box>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                            </Box>
                        </Box>
                    </Box>
                }

                {objOpcoesPersonalizado.opcaoProducao &&
                    <Box ref={pdfRef5} w='100%' my={'30px'} bg={'white'} py={'4'} borderRadius={borRadiostem} borderWidth={borWidthItem} borderColor={borColortem}>
                        <Box w='100%'>
                            <Box display={'flex'} w='100%' mb={'10px'} alignItems={'center'} justifyContent={'center'}>
                                <Text color={textColorTituloModulo} fontSize={titleSize} fontWeight={'bold'}>Acompanhamento De Produção</Text>
                            </Box>
                            <Flex px='20px' justify='flex-start' align='center' >
                                <Text color={textColor} fontSize={'18px'} fontWeight={'light'}>Seringueiro:</Text>
                                <Text color={textColor} fontSize={'18px'} fontWeight={'bold'} ml={'10px'}>{nomePessoaProducao}</Text>
                            </Flex>
                            <Box w='100%' px={'2'} mt={'10px'}>
                                <Box mt='10px' bg={'white'} mb={'30px'} >
                                    {renderAcompanhamentoDaProducao()}
                                </Box>
                                <Box h='380px' mt='30px' bg={'white'} borderWidth={'2px'} borderRadius={'15px'} borderColor={'blackAlpha.400'}>
                                    <ReactApexChart options={optionsProducao} series={seriesProducao} type="line" height={350} width={'100%'} />
                                </Box>
                            </Box>
                            {comentarioProducao != '' &&
                                <Box mt={'3'} px={'5'}>
                                    <Text mb={marginText} color={textColor} fontSize={'18px'} fontWeight={'bold'}>Comentário</Text>
                                    <Box p={'2'} borderWidth={'2px'} borderRadius={'10px'} borderColor={'blackAlpha.300'}>
                                        <Text mb={marginText} color={'gray.500'} fontSize={'15px'}>{comentarioProducao}</Text>
                                    </Box>
                                </Box>
                            }
                            <Box mt={'5'} px={'5'}>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }} gap='20px' mb='20px' mt={'15px'}>
                                    {arrayImagensProducao.length > 0 &&
                                        arrayImagensProducao.map((item, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Card py='10px' align='center' justify={'start'}>
                                                        <Flex my='auto' h='100%' align={{ base: "center", xl: "start" }} justify={{ base: "center", xl: "center" }}>
                                                            <Stat h={'100%'} my='auto' ms={"0px"}>
                                                                <Flex align='center' justify={'center'}>
                                                                    <Image boxSize='200px' objectFit='contain' src={item.fileBase64} alt={'alt'} />
                                                                </Flex>
                                                            </Stat>
                                                        </Flex>
                                                    </Card>
                                                </Box>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                            </Box>
                        </Box>
                    </Box>
                }
                <Box>
                    <Center mt={'20px'} px={'5'} alignItems={'center'} justifyContent={'end'}>
                        <Text color={textColor} fontSize={'15px'} fontWeight={'bold'} px={'3'}>Salvar uma cópia no servidor</Text>
                        <Checkbox
                            size="lg"
                            colorScheme='blue'
                            isChecked={gerarCopiaPdfServidor}
                            borderWidth={'1px'}
                            borderColor={'blackAlpha.700'}
                            onChange={() => setGerarCopiaPdfServidor(!gerarCopiaPdfServidor)}
                            bg={'white'}>
                        </Checkbox>
                    </Center>
                </Box>
                <Wrap spacing='30px' mx={'30px'} mt={'10px'} justify='space-between'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button variant="brand" onClick={downloadPdf}>Gerar PDF</Button>
                </Wrap>
            </Card>
            <LoaderBlack isOpen={loading} />
        </Box>
    );
}