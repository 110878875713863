import React, { useEffect, useState } from "react";
import {
    Box,
    SimpleGrid,
    useColorModeValue,
    Text,
    Flex,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import LetraA from 'assets/img/layout/letra_A.png';
import Qrcode from 'assets/img/layout/qrcode.png';
import Config from 'assets/img/layout/config.png';
import Percent from 'assets/img/layout/percent.png';
import Seringueiro from 'assets/img/layout/seringueiro.png';
import Pontos from 'assets/img/layout/pontos.png';
import Planilha from 'assets/img/layout/planilha.png';
import Seguranca from 'assets/img/layout/seguranca.png';
import Pesquisa from 'assets/img/layout/pesquisa.png';
import TabelasDeApoio from 'assets/img/layout/tabelasDeApoio.png'
import RegrasNegocio from 'assets/img/layout/regras_negocio.png'

export default function Configuracoes() {

    const history = useHistory();
    const [sangriaAtiva, setSangriaAtiva] = useState(false);
    const [idUsuarioLogado, setIdUsuarioLogado] = useState('');

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardShadow = useColorModeValue('0px 3px 8px rgba(0, 0, 0, 0.3)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idUsuarioLogadoP = localStorage.getItem('@HE-idUsuarioLogado');
            setIdUsuarioLogado(idUsuarioLogadoP);

            const sangriaAtivaP = localStorage.getItem('@HE-sangriaAtivaAsync');
            const isAtiva = sangriaAtivaP == 'false' ? true : false;
            setSangriaAtiva(isAtiva);
        }
        loaderScreen();
    }, []);


    function renderConfiguracaoSeringueiro() {
        return (
            <Box>
                <Flex justify='center' align='center' mt={'2px'}>
                    <Flex
                        display={'inline-flex'}
                        flexDirection={'column'}
                        boxShadow={cardShadow}
                        _hover={{ bg: 'blackAlpha.200' }}
                        cursor={'pointer'}
                        onClick={() => { history.push("/admin/configuracao-seringueiro") }}
                        bg={'white'}
                        px={'10px'}
                        py={'10px'}
                        borderRadius={'20px'}
                        justify='center'
                        w={'170px'}
                        h={'140px'}
                        align='center' >
                        <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Configuração Seringueiro'}</Text>
                        <Image h={'60px'} w={'60px'} src={Seringueiro} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderRegrasDeNegocio() {
        return (
            <Box>
                <Flex justify='center' align='center' mt={'2px'}>
                    <Flex
                        display={'inline-flex'}
                        flexDirection={'column'}
                        boxShadow={cardShadow}
                        _hover={{ bg: 'blackAlpha.200' }}
                        cursor={'pointer'}
                        onClick={() => { history.push("/admin/home-documentacao") }}
                        bg={'white'}
                        px={'10px'}
                        py={'10px'}
                        borderRadius={'20px'}
                        justify='center'
                        w={'170px'}
                        h={'140px'}
                        align='center' >
                        <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Regras de'}</Text>
                        <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Negócio'}</Text>
                        <Image h={'60px'} w={'60px'} src={RegrasNegocio} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderRedefinirSenha() {
        return (
            <Box>
                <Flex justify='center' align='center' mt={'2px'}>
                    <Flex
                        display={'inline-flex'}
                        flexDirection={'column'}
                        boxShadow={cardShadow}
                        _hover={{ bg: 'blackAlpha.200' }}
                        cursor={'pointer'}
                        onClick={() => { history.push("/admin/redefinir-senha-admin") }}
                        bg={'white'}
                        px={'10px'}
                        py={'10px'}
                        borderRadius={'20px'}
                        justify='center'
                        w={'170px'}
                        h={'140px'}
                        align='center'>
                        <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Gestão de Senhas'}</Text>
                        <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{''}</Text>
                        <Image h={'60px'} w={'60px'} src={Seguranca} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderGestaoDePropriedades() {
        return (
            <Box>
                <Flex justify='center' align='center' mt={'2px'}>
                    <Flex
                        display={'inline-flex'}
                        flexDirection={'column'}
                        boxShadow={cardShadow}
                        _hover={{ bg: 'blackAlpha.200' }}
                        cursor={'pointer'}
                        onClick={() => { history.push("/admin/remover-pessoa-ua-safra") }}
                        bg={'white'}
                        px={'10px'}
                        py={'10px'}
                        borderRadius={'20px'}
                        justify='center'
                        w={'170px'}
                        h={'140px'}
                        align='center' >
                        <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Gestão de Propriedades'}</Text>
                        <Image h={'60px'} w={'60px'} src={Config} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    function renderTabelasDeApoio() {
        return (
            <Box>
                <Flex justify='center' align='center' mt={'2px'}>
                    <Flex
                        display={'inline-flex'}
                        flexDirection={'column'}
                        boxShadow={cardShadow}
                        _hover={{ bg: 'blackAlpha.200' }}
                        cursor={'pointer'}
                        onClick={() => { history.push("/admin/tabelas-de-apoio") }}
                        bg={'white'}
                        px={'10px'}
                        py={'10px'}
                        borderRadius={'20px'}
                        justify='center'
                        w={'170px'}
                        h={'140px'}
                        align='center' >
                        <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Tabelas de Apoio'}</Text>
                        <Image h={'60px'} w={'60px'} src={TabelasDeApoio} />
                    </Flex>
                </Flex>
            </Box>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%'>
                <Text color={textColor} fontSize='18px' fontWeight={'bold'} lineHeight='100%' align={'center'}>Configuração de propriedades</Text>
                <Text color={'gray.500'} fontSize='15px' fontWeight={'light'} align={'center'}>{'Customize ou configure suas propriedades ou safras'}</Text>
                <SimpleGrid columns={{ base: 1, md: 3, lg: 6, "2xl": 6 }} gap='20px' py={'20px'}>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/sazonalidade-list") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Customizar Sazonalidade'}</Text>
                                <Image h={'60px'} w={'60px'} src={Percent} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/qr-code-generate-admin") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Geração de'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'QR Code'}</Text>
                                <Image h={'60px'} w={'60px'} src={Qrcode} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/tarefas-safra-list") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Customizar'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'nome das tarefas'}</Text>
                                <Image h={'60px'} w={'60px'} src={LetraA} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/customizar-pontuacao") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Editar'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Pontuação'}</Text>
                                <Image h={'60px'} w={'60px'} src={Pontos} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/acompanhamento-seringueiro") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center' >
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Acompanhamento'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Seringueiros'}</Text>
                                <Image h={'60px'} w={'60px'} src={Planilha} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex justify='center' align='center' mt={'2px'}>
                            <Flex
                                display={'inline-flex'}
                                flexDirection={'column'}
                                boxShadow={cardShadow}
                                _hover={{ bg: 'blackAlpha.200' }}
                                cursor={'pointer'}
                                onClick={() => { history.push("/admin/log-programacao") }}
                                bg={'white'}
                                px={'10px'}
                                py={'10px'}
                                borderRadius={'20px'}
                                justify='center'
                                w={'170px'}
                                h={'140px'}
                                align='center'>
                                <Text color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{'Log Programação'}</Text>
                                <Text mb={'20px'} color={textColor} fontSize='16px' fontWeight={'bold'} lineHeight={'100%'} align={'center'}>{''}</Text>
                                <Image h={'60px'} w={'60px'} src={Pesquisa} />
                            </Flex>
                        </Flex>
                    </Box>
                    {(idUsuarioLogado == 15 || idUsuarioLogado == 19) && renderRedefinirSenha()}
                    {(idUsuarioLogado == 15 || idUsuarioLogado == 19) && renderGestaoDePropriedades()}
                    {(idUsuarioLogado == 15 || idUsuarioLogado == 19) && renderTabelasDeApoio()}
                    {(idUsuarioLogado == 15 || idUsuarioLogado == 19) && renderRegrasDeNegocio()}
                    {/* {idUsuarioLogado == 15 && renderConfiguracaoSeringueiro()} */}
                </SimpleGrid>
            </Card>
        </Box>
    );
}
