import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    SimpleGrid,
    Input,
    Textarea,
    Wrap,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import MessageSreen from 'components/message/MessageSreen';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Api from 'store/ApiSpring';
import moment from 'moment';

export default function RegrasDeNegocio() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [nomeSafraP, setnomeSafraP] = useState('');
    const [isValidNomeSafra, setIsValidNomeSafra] = useState(false);

    const [moduloSistema, setModuloSistema] = useState('');
    const [regras, setRegras] = useState('');

    const [observacao, setObservacao] = useState('');

    const [idModuloSistema, setIdModuloSistema] = useState(null);
    const [idRegrasNegocio, setIdRegrasNegocio] = useState(null);
    //const [dtCriacao, setDtCriacao] = useState('');
    const [maxCharacterObservacao, setMaxCharacterObservacao] = useState(2000);
    const [maxCharacterRegras, setMaxCharacterRegras] = useState(5000);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;
    const cardShadowObs = useColorModeValue('0px 2px 10px rgba(0, 0, 0, 0.8)', 'unset');

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            const idModuloSistemaP = localStorage.getItem('@HE-idModuloSistemaParaTelaDeRegras');
            await carregaInformacoesDaTela(idModuloSistemaP);
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setIsVisibleAlert(false);
        }
    }, []);

    const carregaInformacoesDaTela = async (idModuloSistem) => {

        setIdModuloSistema(idModuloSistem);

        const url = `regrasDeNegocio/retornaListaDeRegrasDeNecio?idModuloSistema=${idModuloSistem}`;
        const response = await Api.getRequest(url);
        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = response.respostaRequisicao.data;

            if (res.length > 0) {
                const obj = res[0];

                const descricaoP = obj.descricao != null && obj.descricao != '' ? obj.descricao : '';
                const observacaoP = obj.observacao != null && obj.observacao != '' ? obj.observacao : '';
                const nomeModuloP = obj.nomeModulo;
                const idRegrasNegocioP = obj.idRegrasNegocio;
                const dtCriacaoP = obj.dtCriacao != null ? moment(obj.dtCriacao).format('DD/MM/YYYY') : '';

                setRegras(descricaoP);
                setObservacao(observacaoP);
                setModuloSistema(nomeModuloP);
                setIdRegrasNegocio(idRegrasNegocioP);
                //setDtCriacao(dtCriacaoP);

                const zz1 = descricaoP.length;
                const ttCaracter = 5000 - zz1;
                setMaxCharacterRegras(ttCaracter);

                const obs = observacaoP.length;
                const ttObs = 2000 - obs;
                setMaxCharacterObservacao(ttObs);
            }
        } else {
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const salvarRegra = async () => {
        try {
            const json = {
                idRegrasNegocio: idRegrasNegocio,
                idModuloSistema: idModuloSistema,
                descricao: regras,
                observacao: observacao,
                nomeModulo: moduloSistema,
            }

            const response = await Api.postRequest(`regrasDeNegocio/inserirAndUpdateRegraDeNecio`, json);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                await carregaInformacoesDaTela(idModuloSistema);

                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }
        } catch (error) {
            console.log('Erro na classe RegrasDeNegocio metodo salvarRegra', error);
        }
    }

    const changeRegras = (val) => {
        setRegras(val);
        const xx = val.length;
        const num = 5000 - xx;
        setMaxCharacterRegras(num);
    }

    const changeObservacao = (val) => {
        setObservacao(val);
        const xx = val.length;
        const num = 2000 - xx;
        setMaxCharacterObservacao(num);
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }


    function renderInpus() {
        return (
            <Box>
                <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'}>Nome Safra *</Text>
                        <Input
                            value={nomeSafraP}
                            onChange={(e) => setnomeSafraP(e.target.value)}
                            isInvalid={isValidNomeSafra}
                            errorBorderColor='crimson'
                            variant="filled"
                            placeholder=""
                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                            borderRadius="10px"
                            borderColor={textColor}
                            textColor={textColor}
                            fontSize={'13px'}
                            fontWeight={'bold'}
                            h={'35px'}
                        />
                    </Box>
                    <Box mt={{ base: 4, md: 0 }}>
                        <Flex px={'10px'} py={'5px'} justify='space-around' align='center' bg={'blackAlpha.100'} borderRadius={'10px'} >
                            <Text color={textColor} fontSize='13px' fontWeight={'bold'} ml={'10px'} align={'center'}>Status</Text>
                        </Flex>
                    </Box>
                </SimpleGrid>
            </Box>
        )
    }

    function remderRegras() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Descreva as regras da tela/modulo'}</Text>
                    <Textarea
                        value={regras}
                        onChange={(e) => { changeRegras(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={5000}
                        h={'350px'}
                    />
                </Flex>
                <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterRegras}`}</Text>
            </Box>
        )
    }
    function remderObservacao() {
        return (
            <Box p={'10px'} mt={'20px'} borderRadius={'10'} boxShadow={cardShadowObs}>
                <Flex flexDirection={'column'} align='center' justify={'center'}>
                    <Text mb={'10px'} color={textColor} fontSize={'15px'} fontWeight='bold' lineHeight={'100%'}>{'Observação'}</Text>
                    <Textarea
                        value={observacao}
                        onChange={(e) => { changeObservacao(e.target.value) }}
                        errorBorderColor='crimson'
                        variant="filled"
                        borderRadius="10px"
                        borderColor={'blackAlpha.300'}
                        textColor={textColor}
                        fontSize={'13px'}
                        fontWeight={'medium'}
                        maxLength={2000}
                        h={'200px'}
                    />
                </Flex>
                <Text mt={'5px'} color={'gray.500'} fontSize={'14px'} fontWeight='bold' align={'end'}>{`Caracteres restantes ${maxCharacterObservacao}`}</Text>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'30px'} mt={'30px'} justify='space-between'>
                <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                <Button variant="brand" onClick={salvarRegra}>Salvar</Button>
            </Wrap>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card px='20px' py='10px'>
                {isVisibleAlert && renderAlert()}
                <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} align={'center'}>Editar ou inserir Regras</Text>
                <Flex justify='start' align='center'>
                    <Text color={textColor} fontSize={'18px'} fontWeight={'bold'} align={'center'}>{'Tela/modulo:'}</Text>
                    <Text color={'orange.600'} fontSize={'18px'} fontWeight={'bold'} align={'center'} ml={'15px'}>{moduloSistema}</Text>
                </Flex>
                <Box w={'100%'} mt={'0px'}>
                    {remderRegras()}
                    {remderObservacao()}
                </Box>
                {renderButton()}
            </Card>
        </Box>
    );
}
