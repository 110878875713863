import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    TableContainer,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Api from 'store/ApiSpring';
import { RiEdit2Fill } from "react-icons/ri";
import FormTabelasDeApoio from 'components/tabelasDeApoio/FormTabelasDeApoio';
import MessageSreen from 'components/message/MessageSreen';
import LoaderBlack from 'components/loading/LoaderBlack';

export default function TabelasDeApoio() {

    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');

    const [listClone, setListClone] = useState([]);
    const [valueInputClone, setvalueInputClone] = useState('');
    const [idClone, setIdClone] = useState(null);
    const [openClone, setOpenClone] = useState(false);

    const [listPainel, setListPainel] = useState([]);
    const [valueInputPainel, setvalueInputPainel] = useState('');
    const [idPainel, setIdPainel] = useState(null);
    const [openPainel, setOpenPainel] = useState(false);

    const [listTipoNomenclaturaPainel, setListTipoNomenclaturaPainel] = useState([]);
    const [valueInputTipoNomenclaturaPainel, setvalueInputTipoNomenclaturaPainel] = useState('');
    const [idTipoNomenclaturaPainel, setIdTipoNomenclaturaPainel] = useState(null);
    const [openNomenClaturaDoPainel, setOpenNomenClaturaDoPainel] = useState(false);
    
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const gradientGray = 'linear-gradient(gray.700, gray.600, gray.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            await carregaInformacoesDaTela();
            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setIsVisibleAlert(false);
        }
    }, []);

    const carregaInformacoesDaTela = async () => {

        const url = `tabelasDeApoio/retornaClonesAndPaineisAndTipoNomenclaturaPainel`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = response.respostaRequisicao.data;
            setListPainel(res.listPainel);
            setListClone(res.listClone);
            setListTipoNomenclaturaPainel(res.listTipoNomenclaturaPainel);
        }else{
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    // CLONE
    const showModalClone = async (item, acaoP) => {
        setvalueInputClone('');
        setIdClone(null);

        if (acaoP == 'UPDATE') {
            setvalueInputClone(item.nomeClone);
            setIdClone(item.idClone);
        }
        setOpenClone(true);
    }
    const confirmClone = async (inputValue) => {
        setOpenClone(false);
        setLoading(true);

        const json = {
            idClone: idClone,
            nomeClone: inputValue
        }
        const url = `tabelasDeApoio/insertAndUpdateClone`;
        const response = await Api.postRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregaInformacoesDaTela();
            setLoading(false);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    // PAINEL
    const showModalPainel = async (item, acaoP) => {
        setvalueInputPainel('');
        setIdPainel(null);

        if (acaoP == 'UPDATE') {
            setvalueInputPainel(item.nomePainel);
            setIdPainel(item.idPainel);
        }
        setOpenPainel(true);
    }
    const confirmPainel = async (inputValue) => {
        setOpenPainel(false);
        setLoading(true);

        const json = {
            idPainel: idPainel,
            nomePainel: inputValue
        }
        const url = `tabelasDeApoio/insertAndUpdatePainel`;
        const response = await Api.postRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregaInformacoesDaTela();
            setLoading(false);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    // NOMENCLATURA PAINEL
    const showModalNomenclaturaPainel = async (item, acaoP) => {
        setvalueInputTipoNomenclaturaPainel('');
        setIdTipoNomenclaturaPainel(null);

        if (acaoP == 'UPDATE') {
            setvalueInputTipoNomenclaturaPainel(item.nome);
            setIdTipoNomenclaturaPainel(item.idTipoNomenclaturaPainel);
        }
        setOpenNomenClaturaDoPainel(true);
    }
    const confirmNomenclaturaPainel = async (inputValue) => {
        setOpenNomenClaturaDoPainel(false);
        setLoading(true);

        const json = {
            idTipoNomenclaturaPainel: idTipoNomenclaturaPainel,
            nome: inputValue
        }
        const url = `tabelasDeApoio/insertAndUpdateTipoNomenclaturaPainel`;
        const response = await Api.postRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregaInformacoesDaTela();
            setLoading(false);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    function renderClone() {
        const sizeF = '13px';
        return (
            <Box>
                <Flex px={'20px'} mb={'20px'} mt={'5px'} justify={'end'} align={'center'} >
                    <Button variant="miniBrand" onClick={() => { showModalClone(null, 'INSERT') }}>Novo Clone</Button>
                </Flex>
                <TableContainer borderTopRadius={'8px'} >
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'30px'} bgGradient={gradientGray}>
                                <Th w={'70%'}  >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{'Clone'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'30%'} >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{''}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listClone.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td >
                                            <Flex justify={'start'} align={'center'}>
                                                <Text color={textColor} fontSize={sizeF} fontWeight={'bold'}>{item.nomeClone}</Text>
                                            </Flex>
                                        </Td>
                                        <Td >
                                            <Flex justify={'end'} align={'center'}>
                                                <Button
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="action"
                                                    key={index}
                                                    onClick={() => { showModalClone(item, 'UPDATE') }}>Editar</Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderPainel() {
        const sizeF = '13px';
        return (
            <Box>
                <Flex px={'20px'} mb={'20px'} mt={'5px'} justify={'end'} align={'center'} >
                    <Button variant="miniBrand" onClick={() => { showModalPainel(null, 'INSERT') }}>Novo Tipo Sangria</Button>
                </Flex>
                <TableContainer borderTopRadius={'8px'} >
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'30px'} bgGradient={gradientGray}>
                                <Th w={'70%'}  >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{'Tipo Sangria'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'30%'} >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{''}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listPainel.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td >
                                            <Flex justify={'start'} align={'center'}>
                                                <Text color={textColor} fontSize={sizeF} fontWeight={'bold'}>{item.nomePainel}</Text>
                                            </Flex>
                                        </Td>
                                        <Td >
                                            <Flex justify={'end'} align={'center'}>
                                                <Button
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="action"
                                                    key={index}
                                                    onClick={() => { showModalPainel(item, 'UPDATE') }}>Editar</Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderNomeClaturaDoPainel() {
        const sizeF = '13px';
        return (
            <Box>
                <Flex px={'20px'} mb={'20px'} mt={'5px'} justify={'end'} align={'center'} >
                    <Button variant="miniBrand" onClick={() => { showModalNomenclaturaPainel(null, 'INSERT') }}>Nova Nomenclatura</Button>
                </Flex>
                <TableContainer borderTopRadius={'8px'} >
                    <Table size='sm' variant='striped' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'30px'} bgGradient={gradientGray}>
                                <Th w={'70%'}  >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{'Nomenclatura'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'30%'} >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'}>{''}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listTipoNomenclaturaPainel.map((item, index) => {
                                return (
                                    <Tr key={index}>
                                        <Td >
                                            <Flex justify={'start'} align={'center'}>
                                                <Text color={textColor} fontSize={sizeF} fontWeight={'bold'}>{item.nome}</Text>
                                            </Flex>
                                        </Td>
                                        <Td >
                                            <Flex justify={'end'} align={'center'}>
                                                <Button
                                                    rightIcon={<RiEdit2Fill />}
                                                    variant="action"
                                                    key={index}
                                                    onClick={() => { showModalNomenclaturaPainel(item, 'UPDATE') }}>Editar</Button>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "60px" }}>
            <Card align='center' direction='column' w='100%' px='5px' py='0px'>
                {isVisibleAlert && renderAlert()}
                <Text color={textColor} fontSize='xl' fontWeight='700' lineHeight='100%' align={'center'}>Editar ou inserir itens nas tabelas de apoio</Text>
                <Box w={'100%'} mt={'10px'}>
                    <Tabs isFitted variant='enclosed' borderRadius={'15px'} borderWidth={'0px'} borderColor={'blackAlpha.700'}>
                        <TabList>
                            <Tab _selected={{ color: 'white', bgGradient: gradientHeader }} borderWidth={'1px'} borderColor={'blackAlpha.700'} borderTopLeftRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Clone</Tab>
                            <Tab _selected={{ color: 'white', bgGradient: gradientHeader }} borderWidth={'1px'} borderColor={'blackAlpha.700'} fontSize={'15px'} fontWeight={'bold'}>Tipo de Sangria</Tab>
                            <Tab _selected={{ color: 'white', bgGradient: gradientHeader }} borderWidth={'1px'} borderColor={'blackAlpha.700'} borderTopRightRadius={'15px'} fontSize={'15px'} fontWeight={'bold'}>Nomenclatura do Painel</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                {renderClone()}
                            </TabPanel>
                            <TabPanel>
                                {renderPainel()}
                            </TabPanel>
                            <TabPanel>
                                {renderNomeClaturaDoPainel()}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </Card>
            {openClone && (
                <FormTabelasDeApoio
                    isOpen={openClone}
                    onPressCancel={() => setOpenClone(false)}
                    onConfirm={confirmClone}
                    valueInput={valueInputClone}
                    titulo={'Clone'} />)}
            {openPainel && (
                <FormTabelasDeApoio
                    isOpen={openPainel}
                    onPressCancel={() => setOpenPainel(false)}
                    onConfirm={confirmPainel}
                    valueInput={valueInputPainel}
                    titulo={'Painel'} />)}
            {openNomenClaturaDoPainel && (
                <FormTabelasDeApoio
                    isOpen={openNomenClaturaDoPainel}
                    onPressCancel={() => setOpenNomenClaturaDoPainel(false)}
                    onConfirm={confirmNomenclaturaPainel}
                    valueInput={valueInputTipoNomenclaturaPainel}
                    titulo={'Nomenclatura Painel'} />)}
            {loading && <LoaderBlack isOpen={loading} />}
        </Box>
    );
}
