import React, { useState, useEffect, useRef } from "react";
import {
    Flex,
    useColorModeValue,
    Text,
    Box,
    Button,
    Wrap,
    Input,
    RadioGroup,
    Radio,
    Stack,
    WrapItem,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    TableContainer,
    Image,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";
import { retiraMascaraMaterNumeros } from '../../../util/Masck';
import { retornaNomeDoMes } from '../../../util/FuncoesUteis';
import * as Api from 'store/ApiSpring';
import moment from 'moment';
import * as ActionTypes from '../../../constants/ActionTypes';
import MessageSreen from 'components/message/MessageSreen';
import ModalConfirmacao from 'components/modal/ModalConfirmacao';
import ExcelIcon from 'assets/img/layout/excel_icon_download.png';
import PdfIcon from 'assets/img/layout/pdf_icon_download.png';
import LoaderBlack from 'components/loading/LoaderBlack';

export default function LancarProducao() {

    const pdfRef = useRef();
    const history = useHistory();
    const [arrayAlternacia, setArrayAlternacia] = useState([]);
    const [telaCarregada, setTelaCarregada] = useState(false);

    const [arrayValorTarefa1, setArrayValorTarefa1] = useState([]);
    const [arrayValorTarefa2, setArrayValorTarefa2] = useState([]);
    const [arrayValorTarefa3, setArrayValorTarefa3] = useState([]);
    const [arrayValorTarefa4, setArrayValorTarefa4] = useState([]);
    const [arrayValorTarefaTotal, setArrayValorTarefaTotal] = useState([]);
    const [totalValorTarefa1, setTotalValorTarefa1] = useState('0');
    const [totalValorTarefa2, setTotalValorTarefa2] = useState('0');
    const [totalValorTarefa3, setTotalValorTarefa3] = useState('0');
    const [totalValorTarefa4, setTotalValorTarefa4] = useState('0');
    const [totalValorGeral, setTotalValorGeral] = useState('0');

    const [dtValorTarefa, setDtValorTarefa] = useState('');
    const [dtValorTarefa2, setDtValorTarefa2] = useState('');
    const [dtValorTarefa3, setDtValorTarefa3] = useState('');
    const [dtValorTarefa4, setDtValorTarefa4] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    const [alternanciaSelecionado, setAlternanciaSelecionado] = useState('3');
    const [nomeSeringueiro, setNomeSeringueiro] = useState('');
    const [totalPrevMes, setTotalPrevMes] = useState('');
    const [idSafraPessoa, setIdSafraPessoa] = useState('');
    const [idPessoa, setIdPessoa] = useState('');
    const [idSafra, setIdSafra] = useState('');
    const [idUa, setIdUa] = useState('');
    const [descMesAno, setDescMesAno] = useState('');
    const [idProducaoValorMes, setIdProducaoValorMes] = useState(null);
    const [arrayDadosGeralCompleto, setArrayDadosGeralCompleto] = useState([]);
    const [idTipoLancamento, setIdTipoLancamento] = useState('2');

    const [isValidTotalGeral, setIsValidTotalGeral] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [openModalConfimacao, setOpenModalConfimacao] = useState(false);
    const [arrayListaMeses, setArrayListaMeses] = useState([]);
    const [numMesSelecionado, setNumMesSelecionado] = useState('');
    const [numAnoSelecionado, setNumAnoSelecionado] = useState('');
    const [arrayComTodasAsLetras, setArrayComTodasAsLetras] = useState([]);
    const [nomeRelatorio, setNomeRelatorio] = useState('Lancar-producao');
    const [loading, setLoading] = useState(false);

    const inputText = useColorModeValue("gray.700", "gray.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const GRADIENTE = 'linear-gradient(to bottom, #e6e6e6, #ffffff, #e6e6e6)';
    const inputBg = useColorModeValue("white", "navy.700");
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            try {
                window.scrollTo(0, 0);

                const dia = moment().format('DD');
                const ano = moment().format('YYYY');
                const nmMes = await retornaNomeDoMes(moment().format('YYYY-MM-DD'));
                const nmRelatorio = `Acompanhamento-producao-${dia}-${nmMes}-${ano}`;
                setNomeRelatorio(nmRelatorio);

                const tePr = localStorage.getItem('@HE-dadosTelaLancamentoProducao');
                const obj = JSON.parse(tePr);

                const idUaX = obj.idUa;
                const idSafraX = obj.idSafra;
                const descMesX = obj.descMes;
                const numMesSelecionadoP = obj.numMes;
                const numAnoSelecionadoP = obj.numAno;
                const descMesAnoX = `${descMesX}/${numAnoSelecionadoP}`;
                const idSafraPessoaX = obj.idSafraPessoa;
                const idPessoaX = obj.idPessoa;

                setIdUa(idUaX);
                setIdSafra(idSafraX);
                setIdSafraPessoa(idSafraPessoaX);
                setIdPessoa(idPessoaX);
                setNumMesSelecionado(numMesSelecionadoP);
                setNumAnoSelecionado(numAnoSelecionadoP);
                setDescMesAno(descMesAnoX);

                const url3 = `producao/listaDadosLancarProducao?idUa=${idUaX}&idSafra=${idSafraX}&idSafraPessoa=${idSafraPessoaX}&idPessoa=${idPessoaX}&mes=${numMesSelecionadoP}&ano=${numAnoSelecionadoP}`;
                const response = await Api.getRequest(url3);

                if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                    const res = await response.respostaRequisicao.data;

                    setNomeSeringueiro(res.nome);

                    const prodP = res.producaoValorMesDto;
                    if (prodP != null) {
                        const totalValorTarefa1P = prodP.totalValorTarefa1 != null ? prodP.totalValorTarefa1 : 0;
                        const totalValorTarefa2P = prodP.totalValorTarefa2 != null ? prodP.totalValorTarefa2 : 0;
                        const totalValorTarefa3P = prodP.totalValorTarefa3 != null ? prodP.totalValorTarefa3 : 0;
                        const totalValorTarefa4P = prodP.totalValorTarefa4 != null ? prodP.totalValorTarefa4 : 0;
                        const dtValorTarefa1P = prodP.dtValorTarefa1 != null ? prodP.dtValorTarefa1 : '';
                        const dtValorTarefa2P = prodP.dtValorTarefa2 != null ? prodP.dtValorTarefa2 : '';
                        const dtValorTarefa3P = prodP.dtValorTarefa3 != null ? prodP.dtValorTarefa3 : '';
                        const dtValorTarefa4P = prodP.dtValorTarefa4 != null ? prodP.dtValorTarefa4 : '';
                        const numMesP = prodP.numMes != null ? Number(prodP.numMes) : 0;
                        const numAnoP = prodP.numAno != null && prodP.numAno != 0 ? `${prodP.numAno}` : '';

                        setIdProducaoValorMes(prodP.idProducaoValorMes);
                        setTotalValorTarefa1(`${totalValorTarefa1P}`);
                        setTotalValorTarefa2(`${totalValorTarefa2P}`);
                        setTotalValorTarefa3(`${totalValorTarefa3P}`);
                        setTotalValorTarefa4(`${totalValorTarefa4P}`);
                        setDtValorTarefa(dtValorTarefa1P);
                        setDtValorTarefa2(dtValorTarefa2P);
                        setDtValorTarefa3(dtValorTarefa3P);
                        setDtValorTarefa4(dtValorTarefa4P);

                        if (numMesP > 0 && numAnoP.length > 3) {
                            const mes22 = numMesP < 10 && numMesP > 0 ? `0${numMesP}` : `${numMesP}`;
                            const primeiroDiaMes = `${numAnoP}-${mes22}-01`;
                            const ultimoDiaMes = moment(primeiroDiaMes).endOf('month').format('YYYY-MM-DD');

                            setMinDate(primeiroDiaMes);
                            setMaxDate(ultimoDiaMes);
                        }
                    }
                    const mesesLancarProducao = res.listMesesLancarProducao.filter(x => x.numMes == numMesSelecionadoP);
                    if (mesesLancarProducao.length > 0) {
                        const mesSelecionado = mesesLancarProducao[0];

                        const totalValorMesP = mesSelecionado.totalValorMes;
                        const totalPrevMesP = mesSelecionado.totalPrevMes;
                        const idTipoLancamentoP = mesSelecionado.idTipoLancamento;
                        const qtdTarefas2 = mesSelecionado.idQuantidadeTarefas != null && mesSelecionado.idQuantidadeTarefas != '' ? mesSelecionado.idQuantidadeTarefas : '3';

                        setTotalPrevMes(`${totalPrevMesP}`)
                        setIdTipoLancamento(`${idTipoLancamentoP}`);
                        setTotalValorGeral(`${totalValorMesP}`);
                        setAlternanciaSelecionado(`${qtdTarefas2}`);

                        const arrayTarefaSafra = await criarArrayComTodasTarefas(res.listTarefaSafraItem);
                        setArrayComTodasAsLetras(arrayTarefaSafra);
                        atualizaDadosGeralNaTela(qtdTarefas2, mesSelecionado.listProducaoPessoaAlternanciaItem, arrayTarefaSafra);
                    }
                    setArrayListaMeses(res.listMesesLancarProducao);
                    setArrayDadosGeralCompleto(mesesLancarProducao[0]);

                    setTelaCarregada(true);
                } else {
                    setTelaCarregada(true);
                    setNumStatusResposta(response.numeroStatusResposta);
                    showAlert();
                }
            } catch (error) {
                setTelaCarregada(true);
                console.log('Erro na classe LancarProducao metodo useEffect', error);
            }
        }
        loaderScreen();
    }, []);

    const criarArrayComTodasTarefas = async (arrayTarefas) => {
        let arrayPess = [];
        if (arrayTarefas.length > 0) {
            await new Promise(resolve => {
                resolve(
                    arrayTarefas.map(function (item) {
                        const obj = {
                            label: item.letraCustomizada,
                            value: `${item.idTarefa}`
                        }
                        arrayPess.push(obj);
                    })
                )
            });
        }
        return arrayPess;
    }

    const validarAndcadastrarValores = async () => {
        try {
            let valid = false;
            const obj = await montarArrayItensDinamicos();
            const arrayP = obj.array;
            const datasAndTotalis = obj.datasAndTotalis;
            if (valid) {
                setNumStatusResposta(ActionTypes.VALOR_NAO_PODE_SER_ZERO);
                showAlert();
                window.scrollTo(0, 0);
                return;
            }

            let set = 0;
            let out = 0;
            let nov = 0;
            let dez = 0;
            let jan = 0;
            let fev = 0;
            let mar = 0;
            let abr = 0;
            let mai = 0;
            let jun = 0;
            let jul = 0;
            let ago = 0;
            let idSet = 1;
            let idOut = 1;
            let idNov = 1;
            let idDez = 1;
            let idJan = 1;
            let idFev = 1;
            let idMar = 1;
            let idAbr = 1;
            let idMai = 1;
            let idJun = 1;
            let idJul = 1;
            let idAgo = 1;
            // PEGO O MES QUE ESTOU TRABALHANDO E ALTERO OS VALORES DELE DENTRO DO ARRAY
            const arrayDoMesSelecionado = arrayListaMeses.map(x => x.numMes == numMesSelecionado ?
                {
                    ...x,
                    valorRealizado: totalValorGeral,
                    idTipoLancamento: idTipoLancamento,
                } : x);

            await new Promise(resolve => {
                resolve(
                    arrayDoMesSelecionado.map(x => {
                        if (x.numMes == 1) {
                            jan = x.valorRealizado;
                            idJan = x.idTipoLancamento;
                        } else if (x.numMes == 2) {
                            fev = x.valorRealizado;
                            idFev = x.idTipoLancamento;
                        } else if (x.numMes == 3) {
                            mar = x.valorRealizado;
                            idMar = x.idTipoLancamento;
                        } else if (x.numMes == 4) {
                            abr = x.valorRealizado;
                            idAbr = x.idTipoLancamento;
                        } else if (x.numMes == 5) {
                            mai = x.valorRealizado;
                            idMai = x.idTipoLancamento;
                        } else if (x.numMes == 6) {
                            jun = x.valorRealizado;
                            idJun = x.idTipoLancamento;
                        } else if (x.numMes == 7) {
                            jul = x.valorRealizado;
                            idJul = x.idTipoLancamento;
                        } else if (x.numMes == 8) {
                            ago = x.valorRealizado;
                            idAgo = x.idTipoLancamento;
                        } else if (x.numMes == 9) {
                            set = x.valorRealizado;
                            idSet = x.idTipoLancamento;
                        } else if (x.numMes == 10) {
                            out = x.valorRealizado;
                            idOut = x.idTipoLancamento;
                        } else if (x.numMes == 11) {
                            nov = x.valorRealizado;
                            idNov = x.idTipoLancamento;
                        } else if (x.numMes == 12) {
                            dez = x.valorRealizado;
                            idDez = x.idTipoLancamento;
                        } else {

                        }
                    })
                )
            });
            const arrPro = [{
                setembro: set,
                outubro: out,
                novembro: nov,
                dezembro: dez,
                janeiro: jan,
                fevereiro: fev,
                marco: mar,
                abril: abr,
                maio: mai,
                junho: jun,
                julho: jul,
                agosto: ago,
                idPessoa: idPessoa,
                idSafraPessoa: idSafraPessoa,
                idTipoLancamentoSet: idSet,
                idTipoLancamentoOut: idOut,
                idTipoLancamentoNov: idNov,
                idTipoLancamentoDez: idDez,
                idTipoLancamentoJan: idJan,
                idTipoLancamentoFev: idFev,
                idTipoLancamentoMar: idMar,
                idTipoLancamentoAbr: idAbr,
                idTipoLancamentoMai: idMai,
                idTipoLancamentoJun: idJun,
                idTipoLancamentoJul: idJul,
                idTipoLancamentoAgo: idAgo,
            }]
            const jsonPessoa = {
                idPessoa: idPessoa,
                idUa: idUa,
                idSafra: idSafra,
                idSafraPessoa: idSafraPessoa,
                totalValorMes: totalValorGeral,
                mes: numMesSelecionado,
                ano: numAnoSelecionado,
                listProducaoPessoaAlternanciaItem: arrayP,
                idTipoLancamento: idTipoLancamento,
                listArrayProducaoDto: arrPro,
                producaoValorTarefa: datasAndTotalis,
            }

            const response = await Api.postRequest(`producao/inserirLancamentoProducao`, jsonPessoa);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                setOpenModalConfimacao(true);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.log('Erro na classe LancarProducao metodo validarAndcadastrarValores', error);
        }
    };

    const showAlert = () => {
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const montarArrayItensDinamicos = async () => {
        const arrayCompleto = arrayDadosGeralCompleto.listProducaoPessoaAlternanciaItem;
        const num = Number(alternanciaSelecionado);
        const array = [];

        const datasAndTotalis = {
            idProducaoValorMes: idProducaoValorMes,
            idSafra: idSafra,
            idPessoa: idPessoa,
            numMes: numMesSelecionado,
            numAno: numAnoSelecionado,
            totalValorTarefa1: totalValorTarefa1 != '' ? totalValorTarefa1 : '0',
            totalValorTarefa2: totalValorTarefa2 != '' ? totalValorTarefa2 : '0',
            totalValorTarefa3: totalValorTarefa3 != '' ? totalValorTarefa3 : '0',
            totalValorTarefa4: totalValorTarefa4 != '' ? totalValorTarefa4 : '0',
            dtValorTarefa1: dtValorTarefa != '' ? dtValorTarefa : null,
            dtValorTarefa2: dtValorTarefa2 != '' ? dtValorTarefa2 : null,
            dtValorTarefa3: dtValorTarefa3 != '' ? dtValorTarefa3 : null,
            dtValorTarefa4: dtValorTarefa4 != '' ? dtValorTarefa4 : null,
        }

        for (let i = 0; i < num; i++) {

            const idProducaoPessoaAlternancia = arrayCompleto[i] != undefined ? arrayCompleto[i].idProducaoPessoaAlternancia : null;
            const valorTarefa = arrayValorTarefa1[i] != undefined ? arrayValorTarefa1[i].valorTarefa : '0';
            const valorTarefa2 = arrayValorTarefa2[i] != undefined ? arrayValorTarefa2[i].valorTarefa2 : '0';
            const valorTarefa3 = arrayValorTarefa3[i] != undefined ? arrayValorTarefa3[i].valorTarefa3 : '0';
            const valorTarefa4 = arrayValorTarefa4[i] != undefined ? arrayValorTarefa4[i].valorTarefa4 : '0';
            const valorTarefaTotal = arrayValorTarefaTotal[i] != undefined ? arrayValorTarefaTotal[i].valorTarefaTotal : '0';
            const idTarefa = arrayComTodasAsLetras[i].value;

            const json = {
                idProducaoPessoaAlternancia: idProducaoPessoaAlternancia,
                idSafraPessoa: idSafraPessoa,
                idSafra: idSafra,
                idPessoa: idPessoa,
                idTarefa: idTarefa,
                letraTarefa: '',        // ESSE CARA EU PEGO NO BACK
                valorTarefa: valorTarefa,
                mes: numMesSelecionado,
                ano: numAnoSelecionado,

                totalValorTarefa2: totalValorTarefa2,
                totalValorTarefa3: totalValorTarefa3,
                totalValorTarefa4: totalValorTarefa4,
                totalValorGeral: totalValorGeral,

                valorTarefa2: valorTarefa2,
                valorTarefa3: valorTarefa3,
                valorTarefa4: valorTarefa4,
                valorTarefaTotal: valorTarefaTotal,
            }
            array.push(json);
        }

        const obj = {
            array: array,
            datasAndTotalis: datasAndTotalis,
        }
        return obj;
    }

    const atualizaDadosGeralNaTela = async (val = '3', arr = [], arrayComTodasAsLetrasP) => {
        try {
            const num = Number(val);
            const array = arr;
            let arrTarefa = [];
            let arrTamanhoLote = [];
            let arrValorTarefa1 = [];
            let arrValorTarefa2 = [];
            let arrValorTarefa3 = [];
            let arrValorTarefa4 = [];
            let arrValorTarefaTotal = [];

            if (num > 0) {
                for (let i = 0; i < num; i++) {

                    if (array[i] != undefined) {
                        const item = array[i];

                        const valorTarefa = item.valorTarefa != null && item.valorTarefa != 0 ? `${item.valorTarefa}` : '0';
                        const valorTarefa2 = item.valorTarefa2 != null && item.valorTarefa2 != 0 ? `${item.valorTarefa2}` : '0';
                        const valorTarefa3 = item.valorTarefa3 != null && item.valorTarefa3 != 0 ? `${item.valorTarefa3}` : '0';
                        const valorTarefa4 = item.valorTarefa4 != null && item.valorTarefa4 != 0 ? `${item.valorTarefa4}` : '0';
                        const valorTarefaTotal = item.valorTarefaTotal != null && item.valorTarefaTotal != 0 ? `${item.valorTarefaTotal}` : '0';
                        const valorPrevTarefa = item.valorPrevTarefa != null && item.valorPrevTarefa != 0 ? `${item.valorPrevTarefa}` : '0';
                        const letraTarefa = item.letraTarefa != null && item.letraTarefa != 0 ? `${item.letraTarefa}` : '';
                        const letraCustomizada = item.letraCustomizada != null && item.letraCustomizada != 0 ? `${item.letraCustomizada}` : '';

                        const jsonTamanhoLote = { id: i, valorTarefa: valorTarefa, valorPrevTarefa: valorPrevTarefa, letraTarefa: letraTarefa, letraCustomizada: letraCustomizada };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonValorTarefa1 = { id: i, valorTarefa: valorTarefa };
                        arrValorTarefa1.push(jsonValorTarefa1);

                        const jsonValorTarefa2 = { id: i, valorTarefa2: valorTarefa2 };
                        arrValorTarefa2.push(jsonValorTarefa2);

                        const jsonValorTarefa3 = { id: i, valorTarefa3: valorTarefa3 };
                        arrValorTarefa3.push(jsonValorTarefa3);

                        const jsonValorTarefa4 = { id: i, valorTarefa4: valorTarefa4 };
                        arrValorTarefa4.push(jsonValorTarefa4);

                        const jsonValorTarefaTotal = { id: i, valorTarefaTotal: valorTarefaTotal };
                        arrValorTarefaTotal.push(jsonValorTarefaTotal);

                        const jsonTarefa = {
                            id: i,
                            value: arrayComTodasAsLetrasP[i].value,
                            label: arrayComTodasAsLetrasP[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    } else {
                        const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: arrayComTodasAsLetrasP[i].label };
                        arrTamanhoLote.push(jsonTamanhoLote);

                        const jsonValorTarefa1 = { id: i, valorTarefa: '0' };
                        arrValorTarefa1.push(jsonValorTarefa1);

                        const jsonValorTarefa2 = { id: i, valorTarefa2: '0' };
                        arrValorTarefa2.push(jsonValorTarefa2);

                        const jsonValorTarefa3 = { id: i, valorTarefa3: '0' };
                        arrValorTarefa3.push(jsonValorTarefa3);

                        const jsonValorTarefa4 = { id: i, valorTarefa4: '0' };
                        arrValorTarefa4.push(jsonValorTarefa4);

                        const jsonValorTarefaTotal = { id: i, valorTarefaTotal: '0' };
                        arrValorTarefaTotal.push(jsonValorTarefaTotal);

                        const jsonTarefa = {
                            id: i,
                            value: arrayComTodasAsLetrasP[i].value,
                            label: arrayComTodasAsLetrasP[i].label,
                        }
                        arrTarefa.push(jsonTarefa);
                    }
                }
            } else {
                for (let i = 0; i < 3; i++) {

                    const jsonTamanhoLote = { id: i, valorTarefa: '0', valorPrevTarefa: '0', letraTarefa: arrayComTodasAsLetrasP[i].label };
                    arrTamanhoLote.push(jsonTamanhoLote);

                    const jsonValorTarefa1 = { id: i, valorTarefa: '0' };
                    arrValorTarefa1.push(jsonValorTarefa1);

                    const jsonValorTarefa2 = { id: i, valorTarefa2: '0' };
                    arrValorTarefa2.push(jsonValorTarefa2);

                    const jsonValorTarefa3 = { id: i, valorTarefa3: '0' };
                    arrValorTarefa3.push(jsonValorTarefa3);

                    const jsonValorTarefa4 = { id: i, valorTarefa4: '0' };
                    arrValorTarefa4.push(jsonValorTarefa4);

                    const jsonValorTarefaTotal = { id: i, valorTarefaTotal: '0' };
                    arrValorTarefaTotal.push(jsonValorTarefaTotal);

                    const jsonTarefa = {
                        id: i,
                        value: arrayComTodasAsLetrasP[i].value,
                        label: arrayComTodasAsLetrasP[i].label,
                    }
                    arrTarefa.push(jsonTarefa);
                }
            }
            setArrayAlternacia(arrTamanhoLote);
            setArrayValorTarefa1(arrValorTarefa1);
            setArrayValorTarefa2(arrValorTarefa2);
            setArrayValorTarefa3(arrValorTarefa3);
            setArrayValorTarefa4(arrValorTarefa4);
            setArrayValorTarefaTotal(arrValorTarefaTotal);

        } catch (error) {
            console.log('Erro na classe LancarProducao metodo atualizaDadosGeralNaTela', error);
        }
    }

    const changeValorTarefa1 = async (id, event) => {
        const { value } = event.target;

        const val3 = retiraMascaraMaterNumeros(value);
        const val4 = Number(val3);
        const val = `${val4}`;
        let tt = 0;
        let temp = arrayValorTarefa1.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefa: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefa != '' ? tt + Number(product.valorTarefa) : tt + 0;
            }
            return product;
        });
        setArrayValorTarefa1(temp);
        setTotalValorTarefa1(`${tt}`)
        changeValorTarefaTotal(id, value, 'ARRAY1');
    }

    const changeValorTarefa2 = async (id, event) => {
        const { value } = event.target;

        const val3 = retiraMascaraMaterNumeros(value);
        const val4 = Number(val3);
        const val = `${val4}`;
        let tt = 0;
        let temp = arrayValorTarefa2.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefa2: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefa2 != '' ? tt + Number(product.valorTarefa2) : tt + 0;
            }
            return product;
        });
        setArrayValorTarefa2(temp);
        setTotalValorTarefa2(`${tt}`)
        changeValorTarefaTotal(id, value, 'ARRAY2');
    }

    const changeValorTarefa3 = async (id, event) => {
        const { value } = event.target;

        const val3 = retiraMascaraMaterNumeros(value);
        const val4 = Number(val3);
        const val = `${val4}`;
        let tt = 0;
        let temp = arrayValorTarefa3.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefa3: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefa3 != '' ? tt + Number(product.valorTarefa3) : tt + 0;
            }
            return product;
        });
        setArrayValorTarefa3(temp);
        setTotalValorTarefa3(`${tt}`)
        changeValorTarefaTotal(id, value, 'ARRAY3');
    }

    const changeValorTarefa4 = async (id, event) => {
        const { value } = event.target;

        const val3 = retiraMascaraMaterNumeros(value);
        const val4 = Number(val3);
        const val = `${val4}`;
        let tt = 0;
        let temp = arrayValorTarefa4.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefa4: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefa4 != '' ? tt + Number(product.valorTarefa4) : tt + 0;
            }
            return product;
        });
        setArrayValorTarefa4(temp);
        setTotalValorTarefa4(`${tt}`)
        changeValorTarefaTotal(id, value, 'ARRAY4');
    }

    const changeValorTarefaTotal = async (id, valX, nomeArray) => {

        const qtdInput1 = nomeArray == 'ARRAY1' ? valX : arrayValorTarefa1[id].valorTarefa;
        const qtdInput2 = nomeArray == 'ARRAY2' ? valX : arrayValorTarefa2[id].valorTarefa2;
        const qtdInput3 = nomeArray == 'ARRAY3' ? valX : arrayValorTarefa3[id].valorTarefa3;
        const qtdInput4 = nomeArray == 'ARRAY4' ? valX : arrayValorTarefa4[id].valorTarefa4;

        const soma = Number(qtdInput1) + Number(qtdInput2) + Number(qtdInput3) + Number(qtdInput4);

        const val = `${soma}`;
        let tt = 0;
        let temp = arrayValorTarefaTotal.map((product) => {
            if (id === product.id) {
                tt = val != '' ? tt + Number(val) : tt + 0;
                return {
                    ...product,
                    valorTarefaTotal: val,
                };
            }
            if (id != product.id) {
                tt = product.valorTarefaTotal != '' ? tt + Number(product.valorTarefaTotal) : tt + 0;
            }
            return product;
        });
        setArrayValorTarefaTotal(temp);
        setTotalValorGeral(`${tt}`);
    }

    const SOMA_TOTAL_GERAL = (val, nomeArray) => {
        let soma = 0;
        const valX = val != '' ? Number(val) : 0;
        const qtdInput1 = totalValorTarefa1 != '' ? Number(totalValorTarefa1) : 0;
        const qtdInput2 = totalValorTarefa2 != '' ? Number(totalValorTarefa2) : 0;
        const qtdInput3 = totalValorTarefa3 != '' ? Number(totalValorTarefa3) : 0;
        const qtdInput4 = totalValorTarefa4 != '' ? Number(totalValorTarefa4) : 0;

        if (nomeArray == 'ARRAY1') {
            soma = valX + qtdInput2 + qtdInput3 + qtdInput4;

        } else if (nomeArray == 'ARRAY2') {
            soma = qtdInput1 + valX + qtdInput3 + qtdInput4;

        } else if (nomeArray == 'ARRAY3') {
            soma = qtdInput1 + qtdInput2 + valX + qtdInput4;

        } else if (nomeArray == 'ARRAY4') {
            soma = qtdInput1 + qtdInput2 + qtdInput3 + valX;
        }
        setTotalValorGeral(`${soma}`);
    }

    const rarioOpcoes = async (val) => {
        setIdTipoLancamento(val);

        if (val == '1') {

            const arrTamLote = arrayAlternacia.map((item) => {
                return { ...item, valorTarefa: '0' };
            });
            setArrayAlternacia(arrTamLote);

            const arrValorTarefa1 = arrayValorTarefa1.map((item) => {
                return { ...item, valorTarefa: '0' };
            });
            setArrayValorTarefa1(arrValorTarefa1);

            const arrValorTarefa2 = arrayValorTarefa2.map((item) => {
                return { ...item, valorTarefa2: '0' };
            });
            setArrayValorTarefa2(arrValorTarefa2);

            const arrValorTarefa3 = arrayValorTarefa3.map((item) => {
                return { ...item, valorTarefa3: '0' };
            });
            setArrayValorTarefa3(arrValorTarefa3);

            const arrValorTarefa4 = arrayValorTarefa4.map((item) => {
                return { ...item, valorTarefa4: '0' };
            });
            setArrayValorTarefa4(arrValorTarefa4);

            const arrValorTotal = arrayValorTarefaTotal.map((item) => {
                return { ...item, valorTarefaTotal: '0' };
            });
            setArrayValorTarefaTotal(arrValorTotal);

        } else {
            setTotalValorTarefa1('0');
            setTotalValorTarefa2('0');
            setTotalValorTarefa3('0');
            setTotalValorTarefa4('0');
            setTotalValorGeral('0');
        }
    }

    const limpacampos = async () => {

        const arrTamLote = arrayAlternacia.map((item) => {
            return { ...item, valorTarefa: '0' };
        });
        setArrayAlternacia(arrTamLote);

        const arrValorTarefa1 = arrayValorTarefa1.map((item) => {
            return { ...item, valorTarefa: '0' };
        });
        setArrayValorTarefa1(arrValorTarefa1);

        const arrValorTarefa2 = arrayValorTarefa2.map((item) => {
            return { ...item, valorTarefa2: '0' };
        });
        setArrayValorTarefa2(arrValorTarefa2);

        const arrValorTarefa3 = arrayValorTarefa3.map((item) => {
            return { ...item, valorTarefa3: '0' };
        });
        setArrayValorTarefa3(arrValorTarefa3);

        const arrValorTarefa4 = arrayValorTarefa4.map((item) => {
            return { ...item, valorTarefa4: '0' };
        });
        setArrayValorTarefa4(arrValorTarefa4);

        const arrValorTotal = arrayValorTarefaTotal.map((item) => {
            return { ...item, valorTarefaTotal: '0' };
        });
        setArrayValorTarefaTotal(arrValorTotal);

        setTotalValorTarefa1('0');
        setTotalValorTarefa2('0');
        setTotalValorTarefa3('0');
        setTotalValorTarefa4('0');
        setTotalValorGeral('0');
    }

    const changeSomaTotal1 = (val) => {
        const zz = retiraMascaraMaterNumeros(val);
        const num = zz != '' ? Number(zz) : 0;

        setTotalValorTarefa1(`${num}`);
        SOMA_TOTAL_GERAL(`${num}`, 'ARRAY1');
    }
    const changeSomaTotal2 = (val) => {
        const zz = retiraMascaraMaterNumeros(val);
        const num = zz != '' ? Number(zz) : 0;

        setTotalValorTarefa2(`${num}`);
        SOMA_TOTAL_GERAL(`${num}`, 'ARRAY2');
    }
    const changeSomaTotal3 = (val) => {
        const zz = retiraMascaraMaterNumeros(val);
        const num = zz != '' ? Number(zz) : 0;

        setTotalValorTarefa3(`${num}`);
        SOMA_TOTAL_GERAL(`${num}`, 'ARRAY3');
    }
    const changeSomaTotal4 = (val) => {
        const zz = retiraMascaraMaterNumeros(val);
        const num = zz != '' ? Number(zz) : 0;

        setTotalValorTarefa4(`${num}`);
        SOMA_TOTAL_GERAL(`${num}`, 'ARRAY4');
    }

    const gerarRelatorioExcel = async () => {
        setLoading(true);

        setTimeout(async () => {
            await criarRelatorioExcel();
            setLoading(false);
        }, 1000);
    }

    const criarRelatorioExcel = async () => {
        const json = {
            idUa: idUa,
            idSafra: idSafra,
            idPessoa: idPessoa,
            idSafraPessoa: idSafraPessoa,
            mes: numMesSelecionado,
            ano: numAnoSelecionado,
        }
        const url = `producao/downloadExcelLancarProducao`;
        await Api.postRequestDownloadExcel(url, json, nomeRelatorio);
    }

    const telaGerarPdfLaancarProducao = () => {
        const json = {
            idUa: idUa,
            idSafra: idSafra,
            idPessoa: idPessoa,
            idSafraPessoa: idSafraPessoa,
            numMes: numMesSelecionado,
            numAno: numAnoSelecionado,
            nomeSeringueiro: nomeSeringueiro,
            descMesAno: descMesAno,
        }
        localStorage.setItem('@HE-dadosTelaGerarPdfLancarProducao', JSON.stringify(json));
        history.push("/admin/gerarpdf-lancar-producao");
    }

    const closeModalAndBoBack = () => {
        setOpenModalConfimacao(false);
        history.goBack();
    }

    function renderIcones() {
        return (
            <Box bg={'transparent'}>
                <Flex px='20px' justify='flex-end' mb='3px' align='center'>
                    <Image cursor={'pointer'} boxSize='35px' objectFit='contain' src={ExcelIcon} alt='Dan Abramov' onClick={() => { gerarRelatorioExcel() }} />
                    <Image ml={'20px'} cursor={'pointer'} boxSize='35px' objectFit='contain' src={PdfIcon} alt='Dan Abramov' onClick={telaGerarPdfLaancarProducao} />
                </Flex>
            </Box>
        )
    }

    function renderInfo() {
        return (
            <Box my={'10px'} >
                <Wrap spacing='10px' mb={'5px'} justify='space-evenly' px={'1'} mx={'10px'} bgGradient={GRADIENTE} borderRadius={'10px'}>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Seringueiro'}</Text>
                            <Text color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'}>{nomeSeringueiro}</Text>
                        </Box>
                    </WrapItem>
                    <WrapItem w={'48%'}>
                        <Box w='100%'>
                            <Text color={'gray.500'} fontSize='15px' fontWeight='700' align={'center'}>{'Mês/Ano'}</Text>
                            <Text color={'orange.700'} fontSize='16px' fontWeight='700' align={'center'} textTransform={'capitalize'}>{descMesAno}</Text>
                        </Box>
                    </WrapItem>
                </Wrap>
            </Box>
        )
    }

    function renderRario() {
        return (
            <Box mt={'40px'} display={{ md: 'flex' }} alignItems="center" justifyContent="space-between" >
                <RadioGroup onChange={rarioOpcoes} value={idTipoLancamento} defaultValue='2'>
                    <Stack spacing={5} direction='row'>
                        <Radio size='md' name='1' colorScheme='blue' value='1' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir por Total</Text>
                        </Radio>
                        <Radio size='md' name='1' colorScheme='blue' value='2' borderWidth={'1px'} borderColor={'blue.900'}>
                            <Text color={textColor} fontSize='15px' fontWeight='bold' lineHeight='100%'>Inserir por Tarefa/Lote</Text>
                        </Radio>
                    </Stack>
                </RadioGroup>
            </Box>
        )
    }

    function renderValoresAlternancia() {
        const wid2 = '80px';
        const widthX = '150px';
        return (
            <TableContainer mt={'20px'}>
                <Table size='sm' variant='unstyled' overflowX="auto" whiteSpace="nowrap" >
                    <Thead>
                        <Tr>
                            <Th sx={{ paddingX: 0, paddingY: 0 }}>
                                <Text color={'white'} fontSize='11px' fontWeight='bold' align={'center'}>{''}</Text>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td sx={{ paddingX: 0, paddingY: 0 }}>
                                <Flex flexDirection={'column'}>
                                    <Flex h={'35px'} justify='space-between' align='center' borderTopLeftRadius={'10px'} borderTopRightRadius={'10px'} bgGradient={gradientHeader} >
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{''}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={wid2} justify='center' align='center' >
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{''}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center' >
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                        <Flex h={'100%'} w={widthX} justify='center' align='center'>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Realizado'}</Text>
                                        </Flex>
                                    </Flex>
                                    <Flex h={'40px'} justify='space-between' align='center' bgGradient={gradientHeader}>
                                        <Box w={widthX}>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Tarefa/Lote'}</Text>
                                        </Box>
                                        <Box w={wid2}>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Previsto'}</Text>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Box bg={'white'} borderRadius={'10px'}>
                                                    <Input
                                                        value={dtValorTarefa}
                                                        variant='filled'
                                                        onChange={(e) => setDtValorTarefa(e.target.value)}
                                                        errorBorderColor='crimson'
                                                        placeholder=""
                                                        bg={inputBg}
                                                        color={inputText}
                                                        borderColor={'transparent'}
                                                        borderRadius="10px"
                                                        size="md"
                                                        type="date"
                                                        //min={minDate}
                                                        //max={maxDate}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        w={'140px'}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Box bg={'white'} borderRadius={'10px'}>
                                                    <Input
                                                        value={dtValorTarefa2}
                                                        variant='filled'
                                                        onChange={(e) => setDtValorTarefa2(e.target.value)}
                                                        errorBorderColor='crimson'
                                                        placeholder=""
                                                        bg={inputBg}
                                                        color={inputText}
                                                        borderColor={textColor}
                                                        borderRadius="10px"
                                                        size="md"
                                                        type="date"
                                                        //min={minDate}
                                                        //max={maxDate}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        w={'140px'}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Box bg={'white'} borderRadius={'10px'}>
                                                    <Input
                                                        value={dtValorTarefa3}
                                                        variant='filled'
                                                        onChange={(e) => setDtValorTarefa3(e.target.value)}
                                                        errorBorderColor='crimson'
                                                        placeholder=""
                                                        bg={inputBg}
                                                        color={inputText}
                                                        borderColor={textColor}
                                                        borderRadius="10px"
                                                        size="md"
                                                        type="date"
                                                        //min={minDate}
                                                        //max={maxDate}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        w={'140px'}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Flex justify='center' align='center'>
                                                <Box bg={'white'} borderRadius={'10px'}>
                                                    <Input
                                                        value={dtValorTarefa4}
                                                        variant='filled'
                                                        onChange={(e) => setDtValorTarefa4(e.target.value)}
                                                        errorBorderColor='crimson'
                                                        placeholder=""
                                                        bg={inputBg}
                                                        color={inputText}
                                                        borderColor={textColor}
                                                        borderRadius="10px"
                                                        size="md"
                                                        type="date"
                                                        //min={minDate}
                                                        //max={maxDate}
                                                        fontSize={'13px'}
                                                        fontWeight={'bold'}
                                                        h={'30px'}
                                                        w={'140px'}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box w={widthX}>
                                            <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Total'}</Text>
                                        </Box>
                                    </Flex>
                                </Flex>
                                <Flex justify='space-between' align='center' mt={'10px'}>
                                    <Box w={widthX}>
                                        {arrayAlternacia.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex h={'30px'} justify='center' align='center'>
                                                        <Text color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.letraCustomizada}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={wid2}>
                                        {arrayAlternacia.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex h={'30px'} justify='center' align='center' >
                                                        <Text color={textColor} fontSize='15px' fontWeight='bold' align={'center'}>{item.valorPrevTarefa}</Text>
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa1.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex justify='center' align='center'>
                                                        <Input
                                                            key={index}
                                                            value={item.valorTarefa}
                                                            onChange={e => changeValorTarefa1(item.id, e)}
                                                            errorBorderColor='crimson'
                                                            variant="filled"
                                                            placeholder=""
                                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            h={'30px'}
                                                            w={'100px'}
                                                            disabled={idTipoLancamento == '2' ? false : true}
                                                        />
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa2.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex justify='center' align='center'>
                                                        <Input
                                                            key={index}
                                                            value={item.valorTarefa2}
                                                            onChange={e => changeValorTarefa2(item.id, e)}
                                                            errorBorderColor='crimson'
                                                            variant="filled"
                                                            placeholder=""
                                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            h={'30px'}
                                                            w={'100px'}
                                                            disabled={idTipoLancamento == '2' ? false : true}
                                                        />
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa3.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex justify='center' align='center'>
                                                        <Input
                                                            key={index}
                                                            value={item.valorTarefa3}
                                                            onChange={e => changeValorTarefa3(item.id, e)}
                                                            errorBorderColor='crimson'
                                                            variant="filled"
                                                            placeholder=""
                                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            h={'30px'}
                                                            w={'100px'}
                                                            disabled={idTipoLancamento == '2' ? false : true}
                                                        />
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefa4.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex justify='center' align='center'>
                                                        <Input
                                                            key={index}
                                                            value={item.valorTarefa4}
                                                            onChange={e => changeValorTarefa4(item.id, e)}
                                                            errorBorderColor='crimson'
                                                            variant="filled"
                                                            placeholder=""
                                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            h={'30px'}
                                                            w={'100px'}
                                                            disabled={idTipoLancamento == '2' ? false : true}
                                                        />
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    <Box w={widthX}>
                                        {arrayValorTarefaTotal.map((item, index) => {
                                            return (
                                                <Box key={index} py={'5px'}>
                                                    <Flex justify='center' align='center'>
                                                        <Input
                                                            key={index}
                                                            value={item.valorTarefaTotal}
                                                            //onChange={e => changeValorTarefa3(item.id, e)}
                                                            errorBorderColor='crimson'
                                                            variant="filled"
                                                            placeholder=""
                                                            _placeholder={{ opacity: 0.3, color: 'inherit' }}
                                                            borderRadius="10px"
                                                            borderColor={textColor}
                                                            textColor={textColor}
                                                            fontSize={'13px'}
                                                            fontWeight={'bold'}
                                                            //bg={'gray.50'} 
                                                            h={'30px'}
                                                            w={'100px'}
                                                            textAlign={'center'}
                                                            cursor={'not-allowed'}
                                                            disabled={true}
                                                            _disabled={{ bg: 'rgba(0, 0, 0, 0.1)', textColor: 'gray.900' }}
                                                        />
                                                    </Flex>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Flex>
                                <Flex h={'45px'} mt={'10px'} justify='space-between' align='center' bgGradient={gradientHeader} borderRadius={'8px'}>
                                    <Box w={widthX}>
                                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{'Total'}</Text>
                                    </Box>
                                    <Box w={wid2}>
                                        <Text color={'white'} fontSize='15px' fontWeight='bold' align={'center'}>{totalPrevMes}</Text>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Input
                                                value={totalValorTarefa1}
                                                errorBorderColor='crimson'
                                                variant="filled"
                                                placeholder=""
                                                _focus={{ boxShadow: 'none' }}
                                                borderRadius="10px"
                                                borderColor={textColor}
                                                textColor={textColor}
                                                fontSize={'13px'}
                                                fontWeight={'bold'}
                                                h={'30px'}
                                                w={'100px'}
                                                bg={'white'}
                                                _disabled={{ bg: 'white', textColor: 'gray.400' }}
                                                disabled={idTipoLancamento == '1' ? false : true}
                                                onChange={e => changeSomaTotal1(e.target.value)}
                                            />
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Input
                                                value={totalValorTarefa2}
                                                errorBorderColor='crimson'
                                                variant="filled"
                                                placeholder=""
                                                _focus={{ boxShadow: 'none' }}
                                                borderRadius="10px"
                                                borderColor={textColor}
                                                textColor={textColor}
                                                fontSize={'13px'}
                                                fontWeight={'bold'}
                                                h={'30px'}
                                                w={'100px'}
                                                bg={'white'}
                                                _disabled={{ bg: 'white', textColor: 'gray.400' }}
                                                disabled={idTipoLancamento == '1' ? false : true}
                                                onChange={e => changeSomaTotal2(e.target.value)}
                                            />
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Input
                                                value={totalValorTarefa3}
                                                errorBorderColor='crimson'
                                                variant="filled"
                                                placeholder=""
                                                _focus={{ boxShadow: 'none' }}
                                                borderRadius="10px"
                                                borderColor={textColor}
                                                textColor={textColor}
                                                fontSize={'13px'}
                                                fontWeight={'bold'}
                                                h={'30px'}
                                                w={'100px'}
                                                bg={'white'}
                                                _disabled={{ bg: 'white', textColor: 'gray.400' }}
                                                disabled={idTipoLancamento == '1' ? false : true}
                                                onChange={e => changeSomaTotal3(e.target.value)}
                                            />
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Input
                                                value={totalValorTarefa4}
                                                errorBorderColor='crimson'
                                                variant="filled"
                                                placeholder=""
                                                _focus={{ boxShadow: 'none' }}
                                                borderRadius="10px"
                                                borderColor={textColor}
                                                textColor={textColor}
                                                fontSize={'13px'}
                                                fontWeight={'bold'}
                                                h={'30px'}
                                                w={'100px'}
                                                bg={'white'}
                                                _disabled={{ bg: 'white', textColor: 'gray.400' }}
                                                disabled={idTipoLancamento == '1' ? false : true}
                                                onChange={e => changeSomaTotal4(e.target.value)}
                                            />
                                        </Flex>
                                    </Box>
                                    <Box w={widthX}>
                                        <Flex justify='center' align='center'>
                                            <Box bg={'white'} borderRadius={'10px'}>
                                                <Input
                                                    value={totalValorGeral}
                                                    isInvalid={isValidTotalGeral}
                                                    errorBorderColor='crimson'
                                                    variant="filled"
                                                    placeholder=""
                                                    _focus={{ boxShadow: 'none' }}
                                                    borderRadius="10px"
                                                    borderColor={textColor}
                                                    textColor={textColor}
                                                    fontSize={'13px'}
                                                    fontWeight={'bold'}
                                                    h={'30px'}
                                                    w={'100px'}
                                                    textAlign={'center'}
                                                    cursor={'not-allowed'}
                                                    bg={'white'}
                                                    _disabled={{ bg: 'rgba(0, 0, 0, 0.1)', textColor: 'gray.900' }}
                                                    disabled={true}
                                                //onChange={e => setTotalValorTarefa4(e.target.value)}
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        )
    }


    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'0px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    function renderButton() {
        return (
            <Wrap spacing='30px' mx={'10px'} mt={'20px'} justify='space-between'>
                <Flex px='10px' justify='center' align='center'>
                    <Button variant="brand" onClick={() => { history.goBack() }}>Voltar</Button>
                    <Button ml={'10px'} variant="brand" onClick={() => { limpacampos() }}>Limpar Campos</Button>
                </Flex>
                <Button variant="brand" onClick={validarAndcadastrarValores}>Salvar</Button>
            </Wrap>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box mt={'50px'}>
            <Card direction='column' px='20px' py='10px'>
                {renderAlert()}
                {renderIcones()}

                <Box ref={pdfRef} mt={'10px'} w='100%'>
                    {renderInfo()}
                    {renderRario()}
                    {renderValoresAlternancia()}
                </Box>

                {renderButton()}
            </Card>
            <ModalConfirmacao isOpen={openModalConfimacao} onPress={() => { closeModalAndBoBack() }} />
            {loading && (<LoaderBlack isOpen={loading} />)}
        </Box>
    );
}
