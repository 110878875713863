
export async function retornaHtmlLancamentoProducao(objItens) {
    let result = '';
    try {
        const tituloRelatorio = objItens.tituloRelatorio;
        const formInputsMeses = objItens.formInputsMeses;
        const nomeMesCorrente = objItens.nomeMesCorrente;
        const nomeUa = objItens.nomeUa;
        const nomeMunicipio = objItens.nomeMunicipio;
        const idUf = objItens.idUf;
        const objValoresTotais = objItens.objValoresTotais;

        const resMeses = await retornaMesesLancamento(formInputsMeses, 0, 2);
        const somaTotal = await retornaSomaTotal(formInputsMeses, objValoresTotais);

        result = `
        <!DOCTYPE html>
        <html>
        <head>
            <style>
                * { print-color-adjust:exact !important; }
                #geeks {
                    width: 100%;
                    height: 100vh;
                    font-size: 12px;
                    font-family: Times;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin: 0px;
                    background-color: rgb(255, 255, 255);
                    color: rgb(46, 4, 4);
                    align-items: center;
                }
                @page {
                    margin: 20px;
                }
                .tableDefault {
                    font-family: arial, sans-serif;
                    border-collapse: collapse;
                    width: 100%;
                }
                .descInfo {
                    font-size: 10px;
                    text-align: center;
                    border: 1px solid #cababa;
                    background-color: rgba(248, 245, 245, 0.9);
                    font-stretch: ultra-condensed;
                }
                .evidencia {
                    max-width: 150px;
                    height: auto;
                    margin-left: 10px;
                    margin-top: 2px;
                }            
                .descTotal {
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;
                    color: white;
                    border: 1px solid #cababa;
                    background-color: #4b2703;
                    font-stretch: ultra-condensed;
                }
                .descTotal-yellow {
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;
                    color: black;
                    border: 1px solid #cababa;
                    background-color: yellow;
                    font-stretch: ultra-condensed;
                }
                .descTotal-green {
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;
                    color: black;
                    border: 1px solid #cababa;
                    background-color: lime;
                    font-stretch: ultra-condensed;
                }            
                .descTotalGeral {
                    font-weight: 500;
                    font-size: 11px;
                    text-align: center;
                    padding: 4px;
                    color: white;
                    border: 1px solid #cababa;
                    background: #381301;
                    font-stretch: ultra-condensed;
                }
                .titulo-table {
                    font-weight: normal;
                    font-size: 12px;
                    text-align: center;
                    padding: 4px;
                    border: 1px solid #ddc8c8;
                    background: #993300;
                    color: white;            
                    font-stretch: ultra-condensed;
                } 
                .descTotalGeralValores {
                    font-weight: 500;
                    font-size: 11px;
                    text-align: center;
                    padding: 4px;
                    color: white;
                    border: 1px solid #cababa;
                    background: #e27a12;
                    font-stretch: ultra-condensed;
                }
                .soma-final {
                    font-weight: 500;
                    font-size: 11px;
                    text-align: center;
                    padding: 4px;
                    color: white;
                    border: 1px solid #cababa;
                    background: #4b2703;
                    font-stretch: ultra-condensed;
                }            
                .headerTitulo {
                    text-align: center; 
                    font-size: 15px; 
                    font-weight: bold; 
                    margin-top: 0px; 
                    margin-bottom: 0px; 
                    color: gray;
                    font-stretch: ultra-condensed;
                }
                .seringueiro {
                    text-align: left; 
                    font-size: 15px; 
                    font-weight: bold; 
                    margin-top: 5px; 
                    margin-bottom: 2px; 
                    color: #c58207;
                }
                .mes-ano {
                    font-size: 10px; 
                    font-weight: bold; 
                    text-align: center; 
                    margin-top: 2px; 
                    margin-bottom: 0px;
                    color: #c58207;
                }
                .descParcialAndAno {
                    font-size: 10px;
                    font-weight: bolder;
                    text-align: center;
                    border: 1px solid #cababa;
                    background-color: #e27a12;
                    color: white;
                    font-stretch: ultra-condensed;
                }
                .titulo-transparent {
                    font-weight: normal;
                    font-size: 14px;
                    text-align: center;
                    padding: 4px;
                    color: transparent;
                    font-stretch: ultra-condensed;
                }
                .descInfoTransparent {
                    font-size: 10px;
                    font-stretch: ultra-condensed;
                    color: transparent;
                }
                .page-break {
                    page-break-before: always;
                }                                  
            </style>
        </head>
        <body>
            <div style="margin: 10px;">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="justify-content: flex-start; align-items: flex-start; width: 30%;">
                        <img src="https://storage.googleapis.com/hevea_bucket/system_images/logo_pdf_novo.jpg" alt="Logo Hevea" class="evidencia" />
                    </div>
                    <div style="justify-content: center; align-items: center; width: 40%;">
                        <h1 style="text-align: center; font-size: 25px; font-weight: bold; margin-bottom: 0px; color: #c58207;">${tituloRelatorio}</h1>
                        <h1 class="headerTitulo">${nomeMesCorrente}</h1>
                        <h1 class="headerTitulo">${nomeUa}</h1>
                        <h1 class="headerTitulo">${nomeMunicipio}/${idUf}</h1>
                    </div>
                    <div style="justify-content: flex-start; align-items: flex-start; width: 30%;">
                        <h1 style="font-size: 20px; font-weight: bold; text-align:center;"></h1>
                    </div>        
                </div>
                <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <div style="width: 16px; height: 16px; background-color: yellow; border-radius: 8px;"></div>
                    <h1 style="font-size: 11px; font-weight: bold; text-align:center; margin-left: 10px; margin-bottom: 0px; margin-top: 0px;">Lançamentos por Frequência</h1>
                </div>
                <div style="display: flex; justify-content: flex-start; align-items: center; margin-bottom: 10px;">
                    <div style="width: 16px; height: 16px; background-color: green; border-radius: 8px;"></div>
                    <h1 style="font-size: 11px; font-weight: bold; text-align:center; margin-left: 10px; margin-bottom: 0px; margin-top: 10px;">Lançamentos pelo valor total</h1>
                </div>
                ${resMeses}
                <br>
                ${somaTotal}
            </div>                
        </body>
        </html>`;
    } catch (error) {
        console.log('Erro na classe GerarHtmlLancamentoProducao metodo retornaHtmlLancamentoProducao', error);
    }
    return result;
}

async function retornaMesesLancamento(arryParam) {
    var consumoHtml = '';
    var valhtml01 = '';
    let quebraDePagina = 1;
    if (arryParam.length > 0) {

        for (let i = 0; i < arryParam.length; i++) {
            let valParcial01 = '';
            let valItensTabela01 = '';
            let valItensTabela02 = '';
            let valItensTabela03 = '';
            let htmlQuebraDePagina = '';

            const item = arryParam[i];
            const listMeses = item.listMeses;
            let valNome = '';
            const nome = `
                        <h1 class="seringueiro">${item.nome}</h1>
                    `
            valNome = valNome + nome;

            for (let y = 0; y < 14; y++) {

                if (y < 5) {
                    const x = listMeses[y];
                    let tableAltenancia = '';
                    const valorParcial = x.valorParcial != null ? true : false;
                    const valorAnual = x.valorAnual != null ? true : false;
                    const classP = valorParcial || valorAnual ? 'descParcialAndAno' : 'descInfo';
                    const classR = x.idTipoLancamento == 1 ? 'descTotal-green' : 'descTotal-yellow';

                    const listP = x.listProducaoPessoaAlternanciaItem != null && x.listProducaoPessoaAlternanciaItem.length > 0 ? x.listProducaoPessoaAlternanciaItem : [];
                    for (let h = 0; h < listP.length; h++) {
                        const alte = listP[h];
                        const val02 = `
                                    <tr>
                                        <td class="${classP}">${alte.letraCustomizada}</td>
                                        <td class="${classP}">${alte.valorPrevTarefa}</td>
                                        <td class="${classP}">${alte.valorTarefa}</td>
                                        <td class="${classP}">${alte.percent}%</td>
                                    </tr>
                                `
                        tableAltenancia = tableAltenancia + val02;
                    }
                    const val005 = `
                            <div>
                                <h1 class="mes-ano">${x.descMesAno}</h1>
                                <table class="tableDefault">
                                    <tr>
                                        <th class="titulo-table">Alt</th>
                                        <th class="titulo-table">Prev</th>
                                        <th class="titulo-table">Real</th>
                                        <th class="titulo-table" style="width: 20px;">%</th>
                                    </tr>
                                    ${tableAltenancia}
                                    <tr>
                                        <td class="descTotal">Total</td>
                                        <td class="descTotal">${x.valorPrevisto}</td>
                                        <td class="${classR}">${x.valorRealizado}</td>
                                        <td class="descTotal">${x.percent}%</td>
                                    </tr>
                                </table>
                            </div>
                            `
                    valItensTabela01 = valItensTabela01 + val005;
                } if (y >= 5 && y <= 9) {
                    const x = listMeses[y];
                    const valorParcial = x.valorParcial != null ? true : false;
                    const valorAnual = x.valorAnual != null ? true : false;
                    const classP = valorParcial || valorAnual ? 'descParcialAndAno' : 'descInfo';
                    const classR = x.idTipoLancamento == 1 ? 'descTotal-green' : 'descTotal-yellow';

                    let tableAltenancia = '';

                    const listP = x.listProducaoPessoaAlternanciaItem != null && x.listProducaoPessoaAlternanciaItem.length > 0 ? x.listProducaoPessoaAlternanciaItem : [];
                    for (let h = 0; h < listP.length; h++) {
                        const alte = listP[h];
                        const val02 = `
                                    <tr>
                                        <td class="${classP}">${alte.letraCustomizada}</td>
                                        <td class="${classP}">${alte.valorPrevTarefa}</td>
                                        <td class="${classP}">${alte.valorTarefa}</td>
                                        <td class="${classP}">${alte.percent}%</td>
                                    </tr>
                                `
                        tableAltenancia = tableAltenancia + val02;
                    }
                    const val005 = `
                            <div>
                                <h1 class="mes-ano">${x.descMesAno}</h1>
                                <table class="tableDefault">
                                    <tr>
                                        <th class="titulo-table">Alt</th>
                                        <th class="titulo-table">Prev</th>
                                        <th class="titulo-table">Real</th>
                                        <th class="titulo-table" style="width: 20px;">%</th>
                                    </tr>
                                    ${tableAltenancia}
                                    <tr>
                                        <td class="descTotal">Total</td>
                                        <td class="descTotal">${x.valorPrevisto}</td>
                                        <td class="${classR}">${x.valorRealizado}</td>
                                        <td class="descTotal">${x.percent}%</td>
                                    </tr>
                                </table>
                            </div>
                            `
                    valItensTabela02 = valItensTabela02 + val005;
                } if (y > 9) {
                    const x = listMeses[y];
                    const valorParcial = x.valorParcial != null ? true : false;
                    const valorAnual = x.valorAnual != null ? true : false;
                    const classP = valorParcial || valorAnual ? 'descParcialAndAno' : 'descInfo';
                    const classR = x.idTipoLancamento == 1 ? 'descTotal-green' : 'descTotal-yellow';

                    let tableAltenancia = '';

                    const listP = x.listProducaoPessoaAlternanciaItem != null && x.listProducaoPessoaAlternanciaItem.length > 0 ? x.listProducaoPessoaAlternanciaItem : [];
                    for (let h = 0; h < listP.length; h++) {
                        const alte = listP[h];
                        const val02 = `
                                    <tr>
                                        <td class="${classP}">${alte.letraCustomizada}</td>
                                        <td class="${classP}">${alte.valorPrevTarefa}</td>
                                        <td class="${classP}">${alte.valorTarefa}</td>
                                        <td class="${classP}">${alte.percent}%</td>
                                    </tr>
                                `
                        tableAltenancia = tableAltenancia + val02;
                    }
                    const val005 = `
                            <div>
                                <h1 class="mes-ano">${x.descMesAno}</h1>
                                <table class="tableDefault">
                                    <tr>
                                        <th class="titulo-table">Alt</th>
                                        <th class="titulo-table">Prev</th>
                                        <th class="titulo-table">Real</th>
                                        <th class="titulo-table" style="width: 20px;">%</th>
                                    </tr>
                                    ${tableAltenancia}
                                    <tr>
                                        <td class="descTotal">Total</td>
                                        <td class="descTotal">${x.valorPrevisto}</td>
                                        <td class="${classR}">${x.valorRealizado}</td>
                                        <td class="descTotal">${x.percent}%</td>
                                    </tr>
                                </table>
                            </div>
                            `
                    valItensTabela03 = valItensTabela03 + val005;
                } if (y == 13) {
                    const zz = `
                            <div style="margin-left: 5px;">
                                <h1 style="color: transparent; font-size: 15px; margin-top: 0px; margin-bottom: 0px;">A</h1>
                                <table class="tableDefault">
                                    <tr>
                                        <th class="titulo-transparent">Altern</th>
                                        <th class="titulo-transparent">Prev</th>
                                        <th class="titulo-transparent">Real</th>
                                    </tr>
                                    <tr>
                                        <td class="descInfoTransparent">A</td>
                                        <td class="descInfoTransparent">150</td>
                                        <td class="descInfoTransparent">100</td>
                                    </tr>
                                    <tr>
                                        <td class="descInfoTransparent">B</td>
                                        <td class="descInfoTransparent">150</td>
                                        <td class="descInfoTransparent">100</td>
                                    </tr>
                                    <tr>
                                        <td class="descInfoTransparent">C</td>
                                        <td class="descInfoTransparent">150</td>
                                        <td class="descInfoTransparent">100</td>
                                    </tr>
                                    <tr>
                                        <td class="descInfoTransparent">D</td>
                                        <td class="descInfoTransparent">150</td>
                                        <td class="descInfoTransparent">100</td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 11px; margin-top: 0px; margin-bottom: 0px; color: transparent;">T</td>
                                        <td style="font-size: 11px; margin-top: 0px; margin-bottom: 0px; color: transparent;">0</td>
                                        <td style="font-size: 11px; margin-top: 0px; margin-bottom: 0px; color: transparent;">0</td>
                                    </tr>
                                </table>
                            </div>
                        `
                    valItensTabela03 = valItensTabela03 + zz;
                }
            }

            const resto = quebraDePagina % 2;
            if (resto == 0) {
                htmlQuebraDePagina = `
                        <div class="page-break">
                            <h1 style="text-align: center; font-size: 15px; color: transparent;">2</h1>
                        </div>            
                    `
            } else {
                htmlQuebraDePagina = '';
            }

            const hh = `
                    <div>
                        ${valNome}
                        <div>
                            <div style="display: flex; justify-content: space-between;">
                                ${valItensTabela01}
                            </div>
                            <div style="display: flex; justify-content: space-between;">
                                ${valItensTabela02}
                            </div>
                            <div style="display: flex; justify-content: space-between;">
                                ${valItensTabela03}
                            </div>
                        </div>
                    </div>
                    ${htmlQuebraDePagina}
                    `
            valParcial01 = valParcial01 + hh;
            valhtml01 = valhtml01 + valParcial01;
            quebraDePagina = quebraDePagina + 1;
        }
        consumoHtml = `
                <div>                    
                    ${valhtml01}
                </div>
            `
    }
    return consumoHtml;
}

async function retornaSomaTotal(arryParam, objValoresTotais) {

    var consumoHtml = '';
    var valHtml01 = '';

    if (arryParam.length > 0) {

        const totalPrevParcial = objValoresTotais.totalPrevParcial != null && objValoresTotais.totalPrevParcial != undefined ? objValoresTotais.totalPrevParcial : '0';
        const totalRealParcial = objValoresTotais.totalRealParcial != null && objValoresTotais.totalRealParcial != undefined ? objValoresTotais.totalRealParcial : '0';
        const totalPercentParcial = objValoresTotais.totalPercentParcial != null && objValoresTotais.totalPercentParcial != undefined ? objValoresTotais.totalPercentParcial : '0';
        const totalGeralPrevisto = objValoresTotais.totalGeralPrevisto != null && objValoresTotais.totalGeralPrevisto != undefined ? objValoresTotais.totalGeralPrevisto : '0';
        const totalGeralRealizado = objValoresTotais.totalGeralRealizado != null && objValoresTotais.totalGeralRealizado != undefined ? objValoresTotais.totalGeralRealizado : '0';
        const totalGeralPercentual = objValoresTotais.totalGeralPercentual != null && objValoresTotais.totalGeralPercentual != undefined ? objValoresTotais.totalGeralPercentual : '0';

        for (let i = 0; i < arryParam.length; i++) {
            const item = arryParam[i];
            const x = `
                    <tr>
                        <td class="descTotalGeralValores">${item.nome}</td>
                        <td class="descTotalGeralValores">${item.totalParcialPrevisto}</td>
                        <td class="descTotalGeralValores">${item.totalParcialRealizado}</td>
                        <td class="descTotalGeralValores">${item.totalParcialPercentual}%</td>
                        <td class="descTotalGeralValores">${item.totalPrevisto}</td>
                        <td class="descTotalGeralValores">${item.totalRealizado}</td>
                        <td class="descTotalGeralValores">${item.totalPercentual}%</td>
                    </tr>
                `
            valHtml01 = valHtml01 + x;
        }
        consumoHtml = `
                <div style="display: flex; width: 100%; margin-top: 10px; justify-content: center; align-items: center;">
                    <div style="width: 800px;">
                        <table class="tableDefault">
                            <tr>
                                <th class="descTotalGeral">Seringueiro</th>
                                <th class="descTotalGeral">Total Parcial Previsto</th>
                                <th class="descTotalGeral">Total Parcial Realizado</th>
                                <th class="descTotalGeral">Percentual Parcial</th>
                                <th class="descTotalGeral">Total Previsto</th>
                                <th class="descTotalGeral">Total Realizado</th>
                                <th class="descTotalGeral">Total Percentual</th>
                            </tr>
                            ${valHtml01}
                            <tr>
                                <td class="soma-final">Total</td>
                                <td class="soma-final">${totalPrevParcial}</td>
                                <td class="soma-final">${totalRealParcial}</td>
                                <td class="soma-final">${totalPercentParcial}%</td>
                                <td class="soma-final">${totalGeralPrevisto}</td>
                                <td class="soma-final">${totalGeralRealizado}</td>
                                <td class="soma-final">${totalGeralPercentual}%</td>
                            </tr>
                        </table>
                    </div>
                </div>
            `
    }
    return consumoHtml;
}
