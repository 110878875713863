import * as Api from '../store/ApiSpring';
import * as ActionTypes from '../constants/ActionTypes';

export async function retornaClonesAndPaineisAndTipoNomenclaturaPainel() {
    let result = null;
    try {
        const arrayClone = [];
        const arrayPainel = [];
        const arrayNomenclatura = [];

        const url = `tabelasDeApoio/retornaClonesAndPaineisAndTipoNomenclaturaPainel`;
        const response = await Api.getRequest(url);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            const res = response.respostaRequisicao.data;

            const listClone = res.listClone;
            const listPainel = res.listPainel;
            const listTipoNomenclaturaPainel = res.listTipoNomenclaturaPainel;

            for (let i = 0; i < listClone.length; i++) {
                const item = listClone[i];

                const obj = {
                    label: item.nomeClone,
                    value: `${item.idClone}`,
                }
                arrayClone.push(obj);
            }

            for (let i = 0; i < listPainel.length; i++) {
                const item = listPainel[i];

                const obj = {
                    label: item.nomePainel,
                    value: `${item.idPainel}`,
                }
                arrayPainel.push(obj);
            }

            for (let i = 0; i < listTipoNomenclaturaPainel.length; i++) {
                const item = listTipoNomenclaturaPainel[i];

                const obj = {
                    label: item.nome,
                    value: `${item.idTipoNomenclaturaPainel}`,
                }
                arrayNomenclatura.push(obj);
            }

            const objArray = {
                listCloneCombo: arrayClone,
                listPainelCombo: arrayPainel,
                listTipoNomenclaturaPainelCombo: arrayNomenclatura,
            }

            result = objArray
        }
    } catch (error) {
        console.log('Erro na classe TabelasDeApoioUtil metodo retornaClonesAndPaineisAndTipoNomenclaturaPainel ', error);
    }
    return result;
}
