import React, { useEffect, useState } from "react";
import {
    Box,
    useColorModeValue,
    Text,
    Flex,
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    TableContainer,
    Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MessageSreen from 'components/message/MessageSreen';
import { IoArrowRedo } from "react-icons/io5";
import { BiSolidEditAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Api from 'store/ApiSpring';
import FormTabelasDeApoio from 'components/tabelasDeApoio/FormTabelasDeApoio';

export default function HomeDocumentacao() {

    const history = useHistory();
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState(false);
    const [numStatusResposta, setNumStatusResposta] = useState('02');
    const [listaModulosSistema, setListaModulosSistema] = useState([]);

    const [valueInputClone, setvalueInputClone] = useState('');
    const [idClone, setIdClone] = useState(null);
    const [openClone, setOpenClone] = useState(false);

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const gradientHeader = 'linear-gradient(orange.700, orange.600, orange.500)';
    const border_white = `1px 1px #fff, -1px 1px #fff, 1px -1px #fff, -1px -1px #fff, 1px  1px 5px #555`;

    useEffect(() => {
        async function loaderScreen() {
            window.scrollTo(0, 0);

            await carregaInformacoesDaTela();

            setTelaCarregada(true);
        }
        loaderScreen();
        return () => {
            setIsVisibleAlert(false);
        }
    }, []);

    const carregaInformacoesDaTela = async () => {
        try {
            const url = `regrasDeNegocio/retornaListaModulosSistema`;
            const response = await Api.getRequest(url);
            if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
                const res = response.respostaRequisicao.data;

                setListaModulosSistema(res);
            } else {
                setNumStatusResposta(response.numeroStatusResposta);
                showAlert();
            }

        } catch (error) {
            console.log('Erro na classe HomeDocumentacao metodo carregaInformacoesDaTela', error);
        }
    }

    const acessarModulo = (item) => {
        localStorage.setItem('@HE-idModuloSistemaParaTelaDeRegras', `${item.idModuloSistema}`);
        history.push("/admin/regras-de-negocio");
    }

    const showAlert = () => {
        window.scrollTo(0, 0);
        setIsVisibleAlert(true);
        setTimeout(() => {
            setIsVisibleAlert(false);
        }, 5000);
    }

    const showModalClone = async (item, acaoP) => {
        setvalueInputClone('');
        setIdClone(null);

        if (acaoP == 'UPDATE') {
            setvalueInputClone(item.nomeModulo);
            setIdClone(item.idModuloSistema);
        }
        setOpenClone(true);
    }
    const confirmClone = async (inputValue) => {
        setOpenClone(false);
        setLoading(true);

        const json = {
            idModuloSistema: idClone,
            nomeModulo: inputValue
        }
        const url = `regrasDeNegocio/inserirAndUpdateModuloSistema`;
        const response = await Api.postRequest(url, json);

        if (response.numeroStatusResposta === ActionTypes.SUCESSO_NA_REQUISICAO) {
            await carregaInformacoesDaTela();
            setLoading(false);

            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        } else {
            setLoading(false);
            setNumStatusResposta(response.numeroStatusResposta);
            showAlert();
        }
    }

    function renderClone() {
        const sizeF = '15px';
        let corLinha = false;
        return (
            <Box>
                <Flex px={'20px'} mb={'20px'} justify={'end'} align={'center'} >
                    <Button variant="miniBrand" onClick={() => { showModalClone(null, 'INSERT') }}>Novo Modudo</Button>
                </Flex>
                <TableContainer borderTopRadius={'8px'} >
                    <Table size='sm' variant='unstyled' colorScheme='blackAlpha' whiteSpace={'break-spaces'}>
                        <Thead>
                            <Tr h={'35px'} bgGradient={gradientHeader}>
                                <Th w={'75%'}  >
                                    <Flex justify={'start'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'}>{'Módulos'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'10%'}>
                                    <Flex justify={'center'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Editar'}</Text>
                                    </Flex>
                                </Th>
                                <Th w={'15%'}>
                                    <Flex justify={'center'} align={'center'}>
                                        <Text color={'white'} fontSize={sizeF} fontWeight={'bold'} textTransform={'none'} align={'center'}>{'Acessar Regras'}</Text>
                                    </Flex>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {listaModulosSistema.map((item, index) => {
                                corLinha = !corLinha;
                                return (
                                    <Tr key={index} bg={corLinha ? 'white' : 'rgba(0, 0, 0, 0.1)'}>
                                        <Td>
                                            <Flex justify={'start'} align={'center'}>
                                                <Text color={textColor} fontSize={sizeF} fontWeight={'bold'}>{item.nomeModulo}</Text>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex px={'10px'} justify={'center'} align={'center'}>
                                                <Flex
                                                    px={'5px'}
                                                    py={'5px'}
                                                    justify='center'
                                                    align='center'
                                                    _hover={{ bg: 'gray.300' }}
                                                    borderRadius={'10px'}
                                                    onClick={() => { showModalClone(item, 'UPDATE') }}
                                                    cursor={'pointer'}>
                                                    <Icon as={BiSolidEditAlt} width='25px' height='25px' color={'black'} />
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            <Flex px={'10px'} justify={'center'} align={'center'}>
                                                <Flex
                                                    px={'5px'}
                                                    py={'5px'}
                                                    justify='center'
                                                    align='center'
                                                    _hover={{ bg: 'gray.300' }}
                                                    borderRadius={'10px'}
                                                    onClick={() => { acessarModulo(item) }}
                                                    cursor={'pointer'}>
                                                    <Icon as={IoArrowRedo} width='25px' height='25px' color={'black'} />
                                                </Flex>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    function renderAlert() {
        return (
            <Box position={'relative'}>
                <Box position={'absolute'} top={'-10px'} left={'0px'} right={'0px'}>
                    <Flex px='25px' justify='center' my='5px' align='center' >
                        <MessageSreen numeroStatus={numStatusResposta} isVisibleAlert={isVisibleAlert} closeMesage={() => { setIsVisibleAlert(false) }} />
                    </Flex>
                </Box>
            </Box>
        )
    }

    if (!telaCarregada) {
        return (
            <Flex px='1px' mt={'100px'} justify='center' align='center' py={'1'}>
                <Text as='cite' fontSize={'22px'} textShadow={border_white} fontWeight={'bold'} color={'black'}>{'Carregando...'}</Text>
            </Flex>
        )
    }

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "40px" }}>
            <Card px='10px' py='10px'>
                <Text color={textColor} fontSize={'20px'} fontWeight={'bold'} lineHeight='100%' align={'center'}>Regras de Negócio</Text>
                <Text color={'orange.600'} fontSize={'18px'} fontWeight={'bold'} lineHeight='100%' align={'center'} mt={'5px'}>Selecione um módudo para acessar as regras</Text>
                <Box w={'100%'} mt={'10px'}>
                    {renderClone()}
                </Box>
            </Card>
            {openClone && (
                <FormTabelasDeApoio
                    isOpen={openClone}
                    onPressCancel={() => setOpenClone(false)}
                    onConfirm={confirmClone}
                    valueInput={valueInputClone}
                    titulo={'Modulo'} />)}
        </Box>
    );
}
